import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const Loader = () => {
  return (
    <Box sx={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <CircularProgress/>
    </Box>
  )
}

export default Loader