import React, { useState, useEffect } from 'react';
import {Box,TableCell } from '@mui/material';
import PaginationTable from '../../../../../../component/PaginationTable';
import TopBar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import DateComponent from "../../../../../../component/DatePicker";
import CSVButton from '../../../../../../component/CSVButton';
import { SearchBox } from '../../../../../../component/SearchBox';
import Department from '../../../../../../component/Department';
import { useLocation} from 'react-router';
import BtnSecondary from '../../../../../../component/SecondaryButton';

const CustomTableCell = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis", 
  overflow:"hidden"
};


const Headings = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1.375rem 1rem",
  backgroundColor: "#F5F5F5",
  borderBottom: "none",
  textAlign:"center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

const ExpenseRequests = () => {
    const [date, setDate] = useState(new Date().toLocaleDateString('en-GB')); 
    const [expenseId,setExpenseId] = useState("");
    const [open,setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const {state} = useLocation();
    const { data } = state;
    const [filteredData,setFilteredData] = useState([]);

    console.log(data);

    const headings = [
        { label: "Date", style: {...Headings, width:"6.43rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
        { label: "Emp Name", style: {...Headings, width:"6.43rem", maxWidth: "6.43rem"} },
        { label: "Expense Id", style: {...Headings, width:"6.43rem"} },
        { label: "Category", style: {...Headings, width:"6.43rem"} },
        { label: "Amount", style: {...Headings, width:"6.43rem"} },
        { label: "Approval Status", style: { ...Headings, width:"6.43rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
      ];

    useEffect(()=>{
      setFilteredData(data.filter((item)=>item.date===(new Date(date).toLocaleDateString('en-GB'))));
    },[date]);
    
    useEffect(()=>{
      setFilteredData(data.filter((item)=>(
        searchQuery===""?item:item?.employeeName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )));
    },[searchQuery]);

    return (
        <Box sx={{ width: "100%", paddingBlock:"1rem"}}>
            <TopBar MainHeading={"Expenses"} SubHeading={"Expenses"}/>
            <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
             <Box>          
                   <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1rem", alignItems:"end" }}>
                        <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                            Requests
                        </Box>
                        <BtnSecondary 
                            sx={{ width: '5.56rem', height: '2.25rem', borderRadius: '10px' }} 
                            to={"/app/expense-dashboard"}
                        >
                            Back
                        </BtnSecondary>
                    </Box>      
                    <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBlock:"1.5rem"}}>
                        <SearchBox 
                            styles={{height:"3.5rem", width:"16.625rem"}} 
                            searchQuery={searchQuery} 
                            handleSearch={(value)=>setSearchQuery(value)}
                            placeholder={"Search by location, date"}
                        />   
                        <Department sx={{height:"3.5rem"}}/>
                        <DateComponent value={date} onDateSelect={(date) => setDate(date)} styles={{height:"3.5rem"}}/> 
                        <CSVButton data={data} styles={{padding: '1rem 1.5rem', height:"3.5rem", width:"10rem", borderRadius: '10px'}}/>
                    </Box>
                    <PaginationTable
                          rows={filteredData}
                          headings={headings}
                        //   loading={loading}
                          noRecordMessage={"No Record Found"}
                          link={true}
                          path="/app/expense-requests-details"
                          renderCell={(row) => (
                            <>
                              <TableCell 
                                align="center"
                                sx={{...CustomTableCell, width:"6.43rem",  maxWidth:"6.43rem"}}
                                >
                                  {row.date}
                                </TableCell>
                              <TableCell 
                                align="center"
                                sx={{...CustomTableCell, width:"9rem", maxWidth: "9rem"}}
                              >
                                  {row.employeeName}
                              </TableCell>
                              <TableCell 
                                align="center"
                                sx={{...CustomTableCell, width:"6.43rem", maxWidth: "6.43rem"}} 
                              >
                                  {row.expenseId}
                              </TableCell>
                              <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>{row.category}</TableCell>
                              <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>{row.amount}</TableCell>
                              <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>
                                      <Box sx={{
                                                borderRadius:"4px", 
                                                padding:"4px 8px",
                                                textAlign:"center",
                                                backgroundColor: row.status === "pending" ? "#FFBE64" : (row.status === "rejected" ? "#E493931A" : "#0CBD7D"),
                                                color: row.status === "pending" ? "#212423" : (row.status === "rejected" ? "#E52E2E" : "#FFFFFF")
                                              }}>
                                          {row.status}
                                      </Box>
                              </TableCell>
                            </>
                          )}
                        />
              </Box>
            </MainBox>
        </Box>
    )
}

export default ExpenseRequests;
