import React, { useState, useEffect } from "react";
import { Box, Stack, Tooltip} from "@mui/material";
import { SearchBox } from "../../../../../../component/SearchBox";
import BtnPrimary from "../../../../../../component/PrimaryButton";
import BtnFilter from "../../../../../../component/FilterButton";
import TableCell from "@mui/material/TableCell";
import { getAllEmployee } from "../../../../../../services/employee";
import AddIcon from "../../../../../../assets/icons/plus-with-border.svg";
import FilterIcon from "../../../../../../assets/icons/filter.svg"
import PaginationTable from "../../../../../../component/PaginationTable";
import Topbar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import { getOrgDepartments } from "../../../../../../services/globalData";

const CustomTableCell = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#16151C",
  lineHeight: "1.5rem",
  padding: "0.625rem",
}

const Headings = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#A2A1A8",
  lineHeight: "1.5rem",
  padding: "0.625rem",
}

export default function EmployeesTable() {
  
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const departments = getOrgDepartments();
  console.log(departments);

  useEffect(() => {
    const fetchData = async () => {
    try {
      const res = await getAllEmployee();
      console.log(res);
      setData(res.data.reverse() || []);
      setFilteredData(res.data.reverse() || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }
  fetchData();
  }, []);

  useEffect(()=>{
    setFilteredData(
      data?.filter((item) =>
        searchQuery === ""
          ? item
          : item?.fname?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            item?.lname?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )
    )
},[searchQuery])

  const headings = [
    { label: "Employee Name", style: { ...Headings, width:"15rem" } },
    { label: "Employee ID", style: {...Headings, width:"8.25rem"} },
    { label: "Department", style: {...Headings, width:"8.25rem"} },
    { label: "Designation", style: {...Headings, width:"12rem"} },
    { label: "Type", style: {...Headings, width:"6rem"} },
    { label: "Status", style: {...Headings, width:"6rem"} },
  ];

  return (
    <Box>
      <Topbar/>
      <MainBox sx={{padding:"1.25rem"}}>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            marginBottom={"1.875rem"}
          >
            <SearchBox 
              inputStyle={{height:"3.12rem"}}
              searchQuery={searchQuery}
              handleSearch={(value)=>setSearchQuery(value)}
            />
            <Stack direction="row" alignItems="center" spacing={2}>
              <BtnPrimary 
                sx={{
                  padding: "1.25rem",
                  height:"3.12rem",
                  borderRadius: "10px",
                }}
                to="onboarding"
                startIcon={AddIcon}
              >
                  Add New Employee
              </BtnPrimary>
              <BtnFilter
                sx={{
                  padding: "1.25rem",
                  height:"3.12rem",
                  borderRadius: "10px",
                }}
                startIcon={FilterIcon}
              >
                Filter
              </BtnFilter>
            </Stack>
          </Stack>

          <PaginationTable
            rows={filteredData}
            headings={headings}
            loading={loading}
            link={true}
            path="/app/employee-details"
            renderCell={(row, index) => (
              <>
               <Tooltip title="Click to view details">
                <TableCell sx={{...CustomTableCell, width:"15rem"}}>
                        {row.fname + " " + row.lname}
                </TableCell>
                </Tooltip>
                <TableCell sx={{...CustomTableCell, width:"8.25rem"}}>{row.aliasId}</TableCell>
                <TableCell sx={{...CustomTableCell, width:"8.25rem"}}>
                  {departments?.find(department => department?._id === row?.department)?.name}
                </TableCell>
                <TableCell sx={{...CustomTableCell, width:"12rem"}}>{row.designation}</TableCell>
                <TableCell sx={{...CustomTableCell, width:"6rem"}}>
                    {row.employmentType ? (
                        <Box sx={{
                          borderRadius: "0.25rem",
                          color: "#0081FF",
                          width:"5rem",
                          backgroundColor: "#7152F31A",
                          padding: "3px 8px",
                          fontSize:"0.75rem",
                          lineHeight:"1.5rem"
                        }}>
                          {row.employmentType}
                        </Box>
                      ) : null}
                </TableCell>
                <TableCell sx={{...CustomTableCell, width:"6rem"}}>{row.isActive?"Active":"Inactive"}</TableCell>
              </>
            )}
          />
       </MainBox>
    </Box>
  );
}
