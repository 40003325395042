import React, { Suspense } from "react";
import Loader from "../component/Loader";
const LoginPage = React.lazy(() => import("../app/recoverAccount"));

function AccountRecovery() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <LoginPage />
      </Suspense>
    </>
  );
}

export default AccountRecovery;
