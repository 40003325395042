import React,{useState} from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper, CircularProgress, TextField,} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const StyledLink = styled(Link)({
  color:"inherit",
  textDecoration:"none"
})

const miscStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "150px",
}

const BackendPaginationTable = ({
  rows,
  headings,
  renderCell,
  customWidth,
  noRecordMessage = "",
  loading,
  link,
  path,
  id,
  tableStyle,
  paginationHide,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = (parseInt(event.target.value, 10));
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handlePreviousPage = () => {
    if(page>0)
     {
      const newPage = page-1;
      setPage(newPage);
     }
  };

  const handleNextPage = () => {
    const newPage = page+1;
    setPage(newPage);
  };

  console.log(page, rowsPerPage, rows);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", boxShadow: "none", elevation: 0 }}>
        <TableContainer sx={tableStyle?{...tableStyle}:{}}>
          <Table sx={{  minWidth: customWidth || 550 }}>
            <TableHead>
              <TableRow>
                {headings?.map((heading, index) => (
                  <TableCell key={index} sx={heading.style}>
                    {heading.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {loading ? ( 
            <TableRow>
             <TableCell colSpan={headings?.length}>
             <Box sx={miscStyle}>
                <CircularProgress />
              </Box>
             </TableCell>
            </TableRow>
            ) : rows?.length > 0
                ?  rows?.map((row, rowIndex) => (
                    <TableRow 
                      hover 
                      tabIndex={-1} 
                      key={rowIndex}
                      component={link ? StyledLink : 'tr'}
                      to={link ? `${path}/${row._id || row[id]}` : null} 
                    >
                      {renderCell(row)}
                    </TableRow>
                  ))
                : <TableRow>
                    <TableCell colSpan={headings?.length}>
                       <Box sx={miscStyle}>
                          {noRecordMessage}
                        </Box>
                    </TableCell>
                  </TableRow>
                }
            </TableBody>
          </Table>
        </TableContainer>
        {!paginationHide ? (
         <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", fontSize:"0.875rem", lineHeight:"1.25rem", marginTop:"1rem"}}>
                <Box sx={{display:"flex", gap:"0.5rem"}}>
                      <Box>Rows per page:</Box>
                      <select id="pageSizeSelect" value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                      </select>
                </Box>
                <Box sx={{display:"flex", gap:"0.5rem"}}>
                      <Box>Page: {page+1}</Box>
                      <ChevronLeftIcon 
                         sx={{":hover":{cursor:"pointer"}}}
                         onClick={()=> handlePreviousPage()}
                      />
                      <ChevronRightIcon 
                         sx={{":hover":{cursor:"pointer"}}}
                         onClick={()=> handleNextPage()}
                      />
                </Box>
            </Box>     
        ): null}
      </Paper>
    </Box>
  );
};

export default BackendPaginationTable;
