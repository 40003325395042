import React,{useState, useEffect} from "react";
import {Box, TableCell} from "@mui/material";
import styled from "@emotion/styled";
import BtnPrimary from "../../../../../component/PrimaryButton";
import AddIcon from "../../../../../assets/icons/plus.svg";
import TopBar from "../../../../../component/TopBar";
import MainBox from "../../../container/MainBox";
import Card from "../../../container/Card";
import { AvatarWrapper, AvatarImage } from "../../../container/Avatar";
import PaginationTable from "../../../../../component/PaginationTable";
import { getEmployeeExpense } from "../../../../../services/expense";
import ChartPie from "../../../../../assets/icons/chart-pie.svg";
import Upload from "../../../../../assets/icons/upload.svg";
import BriefCase from "../../../../../assets/icons/briefcase.svg";

const CustomTableCell = styled(TableCell)({
  fontWeight: "300",
  fontSize: "1rem",
  color: "#16151C",
  lineHeight: "1.5rem",
  padding: "1rem",
  textAlign:"center"
});

const TextBox = styled(Box)({
    display: "block",
});

const cardItems = [
  { icon:Upload, label: "Pending for submission", value:"--",updatedBy:"June 14, 2023"},
  { icon:BriefCase, label: "Pending for approval", value:"--",updatedBy:"June 14, 2023"},
  { icon:ChartPie, label: "Claims Approved", value:"--",updatedBy:"June 14, 2023"}
]

const Expenses = () => {
  const [loading,setLoading] = useState(false);
  const [data, setData] = useState([{}]);

  useEffect(() => { 
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getEmployeeExpense();
      console.log(res);
      setData(res?.data.reverse().slice(0, 5));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  }; 

  return (
    <Box sx={{width:"100%"}}>
       <TopBar MainHeading={"Expenses"} SubHeading={"Expenses"}/>
       <MainBox sx={{padding:"2.5rem 1.875rem"}}>
         <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <TextBox sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                Overview
              </TextBox>
              <BtnPrimary 
                sx={{ width: '16.5rem', height:"2.625rem", borderRadius: '4px', fontWeight:"600", fontSize:"1rem", lineHeight:"1.5rem"}} 
                startIcon={AddIcon}
                to="add-new"
              >
                Add Expenses
              </BtnPrimary>
         </Box>
         <Box sx={{width:"100%", display:"flex", gap:"2.34rem", marginTop:"5rem"}}>
              {cardItems.map((item, Expenses) => (
                  <Card sx={{width:"16.87rem", height:"9.62rem", padding:"0.81rem 1.5rem"}}>
                      <Box sx={{width:"100%", display:"flex", alignItems:"center", gap:"1rem"}}>
                         <AvatarWrapper>
                             <AvatarImage src={item.icon} />
                          </AvatarWrapper>
                          <TextBox sx={{fontSize:"0.875rem", fontWeight:"300", lineHeight:"1.375rem", color:"#16151C"}}>
                              {item.label}
                          </TextBox>
                      </Box>
                      <TextBox sx={{fontWeight:"600",fontSize:"1.875rem",lineHeight:"2.5rem", color:"#16151C", marginTop:"0.625rem"}}>
                                {item.value}
                      </TextBox>
                      <TextBox sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"1.25rem"}}>
                               {item.updatedBy}
                      </TextBox>
                  </Card>
                ))
              }
          </Box>
       </MainBox>  
       <MainBox sx={{marginTop:"2.06rem", padding:"3rem 1.875rem"}}>
          <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:"2.375rem"}}>
                  <TextBox sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                    Expenses summary 
                  </TextBox>
                  <BtnPrimary 
                    sx={{ width: '16.5rem', height:"2.625rem", borderRadius: '4px', fontWeight:"600", fontSize:"1rem", lineHeight:"1.5rem"}} 
                    to="/app/employee-expense/my-expense"
                  >
                    View Expenses
                  </BtnPrimary>
          </Box>
          <PaginationTable
            rows={data}
            loading={loading}
            paginationHide
            renderCell={(row, Expenses) => (
              <>
                <CustomTableCell>{row?.id}</CustomTableCell>
                <CustomTableCell sx={{display:"flex", justifyContent:"center"}}>
                  <Box sx={{border: "1px solid #F99500", color:"#F99500", width:"5.375rem", textAlign:"center", borderRadius:"8px"}}>
                     {row?.policyCateogary?.name}
                  </Box>
                </CustomTableCell>
                <CustomTableCell>{row?.date}</CustomTableCell>
                <CustomTableCell>{row?.amount}</CustomTableCell>
                <CustomTableCell>
                  <Box sx={{color: row.status === "pending" ? "#D62525" : "#069855"}}
                  >
                    {row?.status}
                  </Box>
                </CustomTableCell>
              </>
            )}
          />
       </MainBox>
    </Box>
  );
};

export default Expenses;