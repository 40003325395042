import React, { useState, useEffect } from 'react';
import { Box,TableCell } from '@mui/material';
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import {getTrip} from '../../../../../../../services/trips';
import { getEmployeeNames } from '../../../../../../../utils/getEmployeeNames';
import TripInfo from "./TripInfo";
import TableHeader from '../../../../../../../component/TableHeader';
import BackendPaginationTable from '../../../../../../../component/BackendPaginationTable';

const CustomTableCell = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis", 
  overflow:"hidden"
};


const Headings = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1.375rem 1rem",
  backgroundColor: "#F5F5F5",
  borderBottom: "none",
  textAlign:"center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

const TripDetails = () => {
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(""); 
    const [order,setOrder] = useState("desc");
    const [data, setData] = useState([]);
    const [tripId,setTripId] = useState("");
    const [expenseId,setExpenseId] = useState("");
    const [open,setOpen] = useState(false);
    const [filteredData,setFilteredData] = useState([]);
    const [employeeName, setEmployeeName] = useState("");
    const [department, setDepartment] =useState("");
    const [page, setPage] = useState(0); 
    const [rowsPerPage, setRowsPerPage] = useState(10); 

    useEffect(()=>{
      setFilteredData(filteredData.reverse());
    },[order]);

    useEffect(()=>{
      fetchData(page, rowsPerPage, date, department, employeeName?.toLowerCase());      
    },[page, rowsPerPage, date, department, employeeName])
    
      const headings = [
        { label: "Date", style: {...Headings, width:"6.43rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
        { label: "Employee", style: {...Headings, width:"9rem"} },
        { label: "Trip ID", style: {...Headings, width:"6.43rem"} },
        { label: "Total Distance", style: {...Headings, width:"6.43rem", maxWidth: "6.43rem"} },
        { label: "Amount", style: {...Headings, width:"5.43rem"} },
        { label: "Status", style: { ...Headings, width:"6.43rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
      ];

      const fetchData = async (pageNumber, pageSize, date="", department="", employeeName="") => {
          setLoading(true);
          try {
            const res = await getTrip(pageNumber, pageSize, date, department, employeeName);
            console.log(res);
            if(res.success){
              const employeeIds = res.data.map(data => data.submitterId);
              const combinedData = await getEmployeeNames(res.data, employeeIds, "submitterId");  
              const sortedData = combinedData.sort((a, b) => {
                const dateA = new Date(a.startTime);
                const dateB = new Date(b.startTime);
                return order === "asc" ? dateA - dateB : dateB - dateA;
              });              
              const dataTable = sortedData?.map((item)=>({
                 date:(new Date(item.startTime).toLocaleDateString('en-GB')),
                 employeeName:item.employee.fname+" "+item.employee.lname,
                 tripId:item.linkTrip._id,
                 expenseId:item._id,
                 aliasId:item.linkTrip.aliasId,
                 totalDistance:item.linkTrip.distance,
                 amount:item.amount,
                 status:item.status
              }));
              setData(dataTable);
              setFilteredData([...dataTable]);
              console.log(filteredData);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
      };

      const handleRowClick = (tripId, expenseId) => {
        setTripId(tripId);
        setExpenseId(expenseId);
        if(!open){
          setOpen(!open);
        }
      };

      console.log(date);

    return (
        <Box sx={{ width: "100%" }}>
            <Topbar MainHeading={"Trips Details"}/>
            <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
              <Box></Box>
              <TableHeader 
                 department={department}
                 setDepartment={setDepartment}
                 data={data} 
                 setDate={setDate} 
                 setOrder={setOrder}
                 employeeName={employeeName}
                 handleSearch={(value)=>setEmployeeName(value)}
              />
              <Box sx={{display:"flex", gap:"1rem", overflow:"hidden"}}>
                <Box sx={{flex:1}}>
                    <BackendPaginationTable
                        rows={filteredData}
                        headings={headings}
                        loading={loading}
                        noRecordMessage={"No Record Found"}
                        renderCell={(row) => (
                          <>
                            <TableCell 
                              align="center"
                              sx={{...CustomTableCell, width:"6.43rem",  maxWidth:"6.43rem"}}
                            >
                              {row.date}
                            </TableCell>
                            <TableCell 
                              align="center"
                              sx={{...CustomTableCell, width:"9rem", maxWidth: "9rem"}}
                            >
                            {row.employeeName}
                            </TableCell>
                            <TableCell 
                            align="center"
                            sx={{...CustomTableCell, width:"6.43rem", maxWidth: "6.43rem",":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}} 
                            onClick={() => handleRowClick(row.tripId, row.expenseId)}
                          >
                              {row.aliasId}
                          </TableCell>
                            <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>{row.totalDistance || 0} KM</TableCell>
                            <TableCell align="center" sx={{...CustomTableCell, width:"5.43rem"}}>{row.amount}</TableCell>
                            <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>
                                  <Box sx={{
                                            borderRadius:"4px", 
                                            padding:"4px 8px",
                                            textAlign:"center",
                                            backgroundColor: row.status === "pending" ? "#FFBE64" : (row.status === "rejected" ? "#E493931A" : "#0CBD7D"),
                                            color: row.status === "pending" ? "#212423" : (row.status === "rejected" ? "#E52E2E" : "#FFFFFF")
                                          }}>
                                        {row.status}
                                  </Box>
                            </TableCell>
                          </>
                        )}
                        page={page} 
                        setPage={setPage} 
                        rowsPerPage={rowsPerPage} 
                        setRowsPerPage={setRowsPerPage} 
                      />
                </Box>  
                <Box sx={{
                        marginRight: open ? '0px' : '-420px',
                        transitionProperty: 'margin-right', 
                        transitionDuration: '0.5s',
                        transitionTimingFunction: 'ease-in-out', 
                      }}
                >
                  <TripInfo tripId={tripId} expenseId={expenseId} setOpen={setOpen}/>
                </Box>   
              </Box>
            </MainBox>
        </Box>
    )
}

export default TripDetails;
