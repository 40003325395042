import React from 'react';
import { Box, TextField, MenuItem} from '@mui/material';
import styled from '@emotion/styled';
import ErrorMsg from '../component/ErrorMsg';

const TextBox = styled(Box)({
  display: 'block',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: '#818181',
});

const InputStyle = {
  borderRadius: '12px',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight:"300",
  color: '#1E1E1E',
  backgroundColor:'#FAFAFB'
}

const EmpFormField = ({ label, type, value, disabled, options,disabledOptions, onChange, fullwidth, width, validation, placeholder, row}) => {

  return (
    <Box sx={{width: width? width :(fullwidth?'100%':'48%'), marginBottom: '1.125rem' }}>
      <TextBox>{label}</TextBox>
      {type === 'select' ? (
        <TextField
          id="outlined-basic"
          select
          variant="outlined"
          onChange={onChange}
          fullWidth
          value={value}
          disabled={disabled}
          sx={{
            '& .MuiInputBase-root':  {...InputStyle, height: row? `${3.5 * row}rem`:"3.5rem", paddingInline: row? `1rem`:"none"},
            "& .MuiOutlinedInput-notchedOutline":{
                border: 'none'
            }
          }}
        >
          {options &&
            options.map((option) => (
              typeof option === 'object' ? (
                <MenuItem key={option._id} value={option.value} disabled={disabledOptions?.includes(option.value)}>
                  {option.name}
                </MenuItem>
              ) : (
                <MenuItem key={option} value={option} disabled={disabledOptions?.includes(option)}>
                  {option}
                </MenuItem>
              )
            ))}
        </TextField>
      )  :  type === 'file' ? (
      <input
        type="file"
        onChange={onChange}
        style={{
          ...InputStyle,
          display: 'block',
          border: '1px solid #A2A1A833',
          padding: '8px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      />
    ) :  (
        <TextField
          id="outlined-basic"
          variant="outlined"
          onChange={onChange}
          fullWidth
          value={value}
          multiline={fullwidth}
          disabled={disabled}
          placeholder={placeholder}
          rows={row}
          sx={{
            '& .MuiInputBase-root': {...InputStyle, height: row? `${3.5 * row}rem`:"3.5rem", paddingInline: row? `1rem`:"none"},
            "& .MuiOutlinedInput-notchedOutline":{
              border: 'none'
             }
          }}
        />
      )}
      <ErrorMsg>{validation}</ErrorMsg>
    </Box>
  );
};

export default EmpFormField;
