import React, {useState, useEffect} from 'react';
import styled from "@emotion/styled";
import BtnPrimary from "../../../../../component/PrimaryButton";
import {Avatar, Box,CircularProgress} from '@mui/material';
import TopBar from "../../../../../component/TopBar";
import MainBox from "../../../container/MainBox";
import FormField from '../../../../../component/Formfield';
import ShareExpense from './ShareExpense';
import DragDrop from '../../../../../component/DragDrop';
import { createExpense, getAllExpenseCategory } from '../../../../../services/expense';
import { getSelfPolicy } from '../../../../../services/policy';
import DateField from '../../../../../component/DateField';
import { getLegalUnitList } from '../../../../../services/globalData';
import SuccessDialog from '../../../../../component/SuccessDialog';
import ProgressBar from '../../../../../component/ProgressBar';
import AddDocument from '../../../../../assets/icons/add-document.svg';

const Label = styled(Box)({
    fontWeight: "500",
    fontSize: "1rem",
    lineHeight:"1.5rem",
    color:"#141414",
    marginBlock: "1rem",
  });


export default function AddNewExpense() {
  const [apiCall,setApiCall]=useState(false);
  const [error,setError]=useState("");
  const [expenseForm,setExpenseForm] = useState({
    policy:"",
    type:"ANY",
    reason:"",
    amount:"",
    shareWith:null,
    billUrl:null,
    date:null,
    currencyType:""
  })
  const [validationErrors, setValidationErrors] = useState({})
  const [shareExpense,setShareExpense]=useState(false);
  const [startDateDialogOpen, setStartDateDialogOpen] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  useEffect(()=>{
    getPoliciesAndCategories();
  },[]);

  const getPoliciesAndCategories = async () =>{
    const res = await getSelfPolicy();
    console.log(res);
    const filteredPolicies = res.data.foundPolicy.filter(policy => policy.type === "ANY");

    console.log(filteredPolicies);

    const policyCategories = [...new Set(filteredPolicies.flatMap(policy => policy.policyCateogary))];

    console.log(policyCategories);

    const policyCategoriesModified = policyCategories.map(category => {
      const matchingObject = filteredPolicies?.find(policy => policy?.policyCateogary?.find(cat => cat._id === category._id));
      if (!category.policy) {
        category.policy = [];
      }
      if (matchingObject) {
        category.policy.push(matchingObject._id);
      }
      return category;
    });

    console.log(policyCategoriesModified);

    const legalUnit = getLegalUnitList();
    const result = await getAllExpenseCategory(legalUnit[0]._id);
    
    const categories =policyCategoriesModified.map(category => {
      const matchingObject = result?.data?.find(data => data._id === category._id);
      if (matchingObject) {
        category.name = matchingObject.name;
        category.value = matchingObject._id;
      }
      return category;
    });

    console.log(categories);
    setCategoryOptions(categories);
  }

  const handleChange = (field, value) => {
    setExpenseForm(prevState => ({...prevState, [field]: value}));
    setValidationErrors(prevState => ({...prevState, [field]: ""}));
  };

  const onSubmit = async() => {
    const errors ={};
    if (!expenseForm.reason) {
      errors.reason="Reason is required";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      setApiCall(true);
      expenseForm.policyCateogary = selectedCategory; 
      expenseForm.policy = categoryOptions?.find(category => category?._id===selectedCategory)?.policy[0]; 
      console.log(expenseForm); 
      const result = await createExpense(expenseForm);
      console.log(result);

      if(result.success){
        setIsSuccessDialogOpen(true);
        setExpenseForm({
          policy:"",
          type:"ANY",
          reason:"",
          amount:"",
          shareWith:null,
          billUrl:null,
          date:null,
          currencyType:""
        });
        setSelectedCategory("");
      }

    } catch (error) {
        console.error('Error in expense creation:', error);
    }
    setApiCall(false);
 };

  return (
    <Box sx={{width:"100%"}}>
          <TopBar MainHeading={"Expenses"} SubHeading={"Expenses > Add New Expenses"}/>
          <MainBox sx={{padding:"1.4375rem"}}>
              <Box sx={{marginBottom:"1.9375rem", width:"80%"}}>
                          <Box sx={{display:"flex"}}>
                            <Avatar src={AddDocument} style={{ width: '1.5rem', height: '1.5rem' }}/>
                            <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                                Add New Expenses
                            </Box>
                          </Box>
                          <ProgressBar value={20}/>                
              </Box>
              <Box sx={{display:"flex", justifyContent:"space-between"}}>
                <Box sx={{width:"48%"}}>
                  <Label>Choose Expense Category</Label>
                  <FormField
                    type="select"
                    options={categoryOptions}
                    value={selectedCategory}
                    fullwidth
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  />  
                </Box>
                <Box sx={{width:"48%"}}>
                  <Label>Select Expense Date</Label>
                  <DateField
                      past={true}
                      value={expenseForm.date}
                      isDialogOpen={startDateDialogOpen}
                      validation={validationErrors.date}
                      fullwidth
                      openDialog={() => setStartDateDialogOpen(true)}
                      closeDialog={() => setStartDateDialogOpen(false)}
                      onSelect={(date) => {
                                handleChange('date', date)
                                setStartDateDialogOpen(false)
                                }}
                  />
                </Box>
              </Box>
              <Label>Reason</Label>
              <FormField
                selectedCategory="text"
                value={expenseForm.reason}
                validation={validationErrors.reason}
                fullwidth
                placeholder="Expense Description (max 100 words)"
                onChange={(e) => handleChange('reason', e.target.value)}
                row={4}
              />  
              <Label>Amount</Label>
              <Box sx={{display:"flex", justifyContent:"space-between"}}>
                  <Box sx={{flex:1, display:"flex", gap:"1.1875rem"}}>
                    {/* <FormField
                          selectedCategory="select"
                          width={"20%"}
                          options={currencyList}
                          value={expenseForm.currencyType}
                          onChange={(e) => handleChange('currencyType', e.target.value)}
                    /> */}
                    <FormField
                      selectedCategory="text"
                      value={expenseForm.amount}
                      validation={validationErrors.amount}
                      onChange={(e) => handleChange('amount', e.target.value)}
                    />  
                  </Box>
                  {/* <Button 
                    sx={{ 
                       borderRadius: '12px',
                       fontSize: '0.875rem',
                       lineHeight: '1.31rem',
                       fontWeight:"500",
                       color: '#8B9390',
                       backgroundColor:'#FAFAFB',
                       textTransform:"none",
                       height:"3.5rem",
                       width:"16.43rem"
                    }}
                    startIcon={<Avatar src={AddExpenseIcon} style={{ width: '1.25rem', height: '1.25rem' }}/>}
                    onClick={()=>setShareExpense(true)}
                  >
                    Share Expense
                  </Button> */}
              </Box>
              <Label>Upload Bill</Label>
              <DragDrop 
               file={expenseForm.billUrl}
               setFile={(e) => handleChange('billUrl', e)}
               style={{
                width:"100%", 
                borderRadius:"12px",
                border:"1px dashed #8B9390",
                backgroundColor:"#FAFAFB",
                display:"flex",
                flexDirection:"column",
                justifyContent:"center",
                alignItems:"center",
                height:"11.43rem",
                "&:hover":{
                  cursor:"pointer"
                }
              }}/>
              <Box sx={{display:"flex", justifyContent:"center", marginTop:"2rem"}}>
                  <BtnPrimary onClick={onSubmit} sx={{width:"34.25rem",height:"2.62rem", borderRadius:"4px"}}>
                    {apiCall?<CircularProgress/>:"Submit"}
                  </BtnPrimary>
              </Box>
          </MainBox>  
          <ShareExpense open={shareExpense} setOpen={setShareExpense} onClose={()=>setShareExpense(false)} />
          <SuccessDialog 
            open={isSuccessDialogOpen} 
            onClose={()=>setIsSuccessDialogOpen(false)}
            message="Your Expense request has been submitted successfully!"
            addNewLink="/app/employee-expense/add-new" 
            addNewText="Add New Expense"
            viewAllLink="/app/employee-expense/my-expense"
            viewAllText="View All Expenses"
          />
    </Box>
  );
}
