import { postCall, getCall, putCall } from "./api";

export const createAttendancePolicy = async (input = {}) => {
  const request = {
    path: `/attendance-policy`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const getAllAttendancePolicies = async () => {
  const request = {
    path: `/attendance-policy`,
  };
  return await getCall(request);
};

export const getAttendancePolicyById = async (id) => {
  const request = {
    path: `/attendance-policy/${id}`,
  };
  return await getCall(request);
};

export const updateAttendancePolicy = async (id, input = {}) => {
  const request = {
    path: `/attendance-policy/${id}`,
    bodyParams: input,
  };
  return await putCall(request);
};

export const getSelfAttendancePolicy = async () => {
  const request = {
    path: '/attendance-policy/self',
  };
  return await getCall(request);
};
