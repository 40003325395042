import React, {useState, useEffect} from 'react';
import styled from "@emotion/styled";
import BtnPrimary from "../../../../../component/PrimaryButton";
import {InputAdornment, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import ExpandableSelect from "./SelectLeave";
import DateDialog from "./DateDialog";
import CalenderIcon from "../../../../../assets/icons/calendar-dash.svg";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {leaveRequest} from "../../../../../services/leave";
import CheckboxField from '../../../../../component/CheckboxField';
dayjs.extend(isBetween);

const TextBox = styled(Box)({
    width: "100%",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight:"1.5rem",
    marginTop: "1.5rem",
    marginBottom: "0.5rem",
  });

const InputBoxStyles ={
    backgroundColor:"#008CC405",
    borderRadius:"4px",
    fontSize:"1rem",
    lineHeight:"1.5rem",
    color:"#5C5C5C"
}

const leaveRecordStyles ={
  fontSize:"0.875rem", lineHeight:"1.31rem"
}

export default function ApplyLeave({ open, onClose }) {

  const [leaveType, setLeaveType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [halfDate, setHalfDate] = useState(null);
  const [halfLeaveList,setHalfLeaveList] = useState([]); //array containing half days
  const [startDialogOpen, setStartDialogOpen] = useState(false);
  const [endDialogOpen, setEndDialogOpen] = useState(false);
  const [halfDialogOpen, setHalfDialogOpen] = useState(false);
  const [isHalfDay,setIsHalfDay] = useState(false);
  const [reason, setReason] = useState('');
  const [days, setDays] = useState([]); //array to hold days object
  const [halfCount,setHalfCount] = useState('');
  const [fullCount, setFullCount] = useState('');

  const leaveForm = {
    leaveType: leaveType,
    days: days,
    reason: reason
    }

    const handleClose = () => {
      setLeaveType('');
      setStartDate(null);
      setEndDate(null);
      setIsHalfDay(false);
      setHalfDate(null);
      setReason('');
      setDays([]);
      setHalfLeaveList([]);
      setFullCount('');
      setHalfCount('');
      onClose();
    }; 
    
  const handleSubmit = async () => {
    console.log(leaveForm);
    const res = await leaveRequest(leaveForm);
    console.log(res);
    setLeaveType('');
    setStartDate(null);
    setEndDate(null);
    setIsHalfDay(false);
    setHalfDate(null);
    setReason('');
    onClose()
  };

  const handleLeave = (value) => {setLeaveType(value)}; 

  const handleStartDateSelect = (date) => {
    setStartDate(date);
    setStartDialogOpen(false); 
  };

  const handleEndDateSelect = (date) => {
    setEndDate(date);
    setEndDialogOpen(false);
  };

  const generateDaysArray = () => {
    const daysArray = [];
    const currentDate = new Date(startDate);
    const end = new Date(endDate);
    while (currentDate <= end) {
      const unixTimestampUTC = currentDate.getTime();
      daysArray.push({ date: unixTimestampUTC, window: 'full-day'});
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }
    setDays(daysArray);
  };
  

  useEffect(() => {
    if (startDate && endDate) {generateDaysArray();}
  }, [startDate, endDate]);

  useEffect(() => {
    let full = 0;
    let half = 0;
    days.forEach((day) => {
      if (day.window === 'full-day') {
        full++;
      } else {
        half++;
      }
    });
    setFullCount(full);
    setHalfCount(half);
  }, [days]);

  const handleHalfDateSelect = (date) => {
    setHalfDate(date);
    setHalfDialogOpen(false);
  };

  const handleHalfSelected = () =>{
    const check = new Date(halfDate);
    const halfDateTimestamp = check.getTime();
    console.log(halfDateTimestamp);
    const updatedDays = days.map(day => {
      if (day.date === halfDateTimestamp) {
          return {...day, window:"first-half"};
      }
      return day;
  });
  setDays(updatedDays);
  }

  useEffect(() => {
    if (halfDate && !halfLeaveList.includes(halfDate)) {
      handleHalfSelected();
      setHalfLeaveList(prevHalfLeaveList => [...prevHalfLeaveList, halfDate]);
    }
  }, [halfDate, halfLeaveList]);

  useEffect(() => {
    if (isHalfDay) {
      setHalfDate(null); 
    }
  }, [isHalfDay]);

  useEffect(() => {
    setEndDate(null);
    setIsHalfDay(false);
    setHalfDate(null);
    setHalfLeaveList([]);
  }, [startDate]);
  
  useEffect(() => {
    setIsHalfDay(false);
    setHalfDate(null);
    setHalfLeaveList([]);
  }, [endDate]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
        sx: {
            width: "50rem",
            borderRadius: "12px",
            boxShadow: "0px 4px 20px 0px #00000040",
            padding: "1.625rem"
        }
      }}
    >
      <DialogTitle sx={{alignSelf:"center", fontWeight:500, fontSize:"1.25rem", lineHeight:"1.875rem"}}>Apply For Leave </DialogTitle>
      <DialogContent>

          <Box>
             <TextBox>Choose Leave Type</TextBox>
             <ExpandableSelect onLeaveSelect={handleLeave}/>
          </Box>

          <Box>
             <TextBox>Start Date</TextBox>
             <TextField
              placeholder="YYYY-MM-DD"
              value={startDate || ''}
              onClick={() => setStartDialogOpen(true)}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={CalenderIcon} alt="Calendar" />
                  </InputAdornment>
                ),
              }}
              sx={{"& .MuiInputBase-root":InputBoxStyles}} 
            />
            <DateDialog open={startDialogOpen} onClose={() => setStartDialogOpen(false)} onSelect={handleStartDateSelect} />
          </Box>

          <Box>
             <TextBox>End Date</TextBox>
             <TextField
              placeholder="YYYY-MM-DD"
              value={endDate || ''}
              onClick={() => setEndDialogOpen(true)}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={CalenderIcon} alt="Calendar" />
                  </InputAdornment>
                ),
              }}
              sx={{"& .MuiInputBase-root":InputBoxStyles}} 
            />
            <DateDialog open={endDialogOpen} onClose={() => setEndDialogOpen(false)} onSelect={handleEndDateSelect} currentStartDate={startDate}/>
          </Box>

          <Box>
             <TextBox>Half Day</TextBox>
             <CheckboxField
                   label="Is It half leave ?"
                   checked={isHalfDay}
                   onChange={setIsHalfDay}
                   labelStyle={{fontSize: "0.875rem", lineHeight: "1.3125rem", color: "#5C5C5C" }}
                   checkboxStyle={{fontSize:"1rem"}}
             />
          </Box>

          {isHalfDay && (<Box>
             <TextBox>Half Day Date</TextBox>
             <TextField
              placeholder="YYYY-MM-DD"
              value={halfDate || ''}
              onClick={() => setHalfDialogOpen(true)}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={CalenderIcon} alt="Calendar" />
                  </InputAdornment>
                ),
              }}
              sx={{"& .MuiInputBase-root":InputBoxStyles}} 
            />
            <DateDialog open={halfDialogOpen} onClose={() => setHalfDialogOpen(false)} onSelect={handleHalfDateSelect} currentStartDate={startDate} currentEndDate={endDate} isHalfDay={isHalfDay}/>
          </Box>
          )}

         <Box sx={{width:"100%", marginTop:"1rem"}}>
          {startDate && endDate && fullCount!==0 && (
            <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", marginTop:"0.75rem"}}>
               <Box sx={{...leaveRecordStyles, flex:1}}>
                 {dayjs(startDate).format("DD-MM-YYYY")} - {dayjs(endDate).format("DD-MM-YYYY")}
               </Box>
            <Box sx={{...leaveRecordStyles,}}>Full Leave</Box>
               <Box sx={{...leaveRecordStyles,flex:1, textAlign:"center"}}>
                 {fullCount} leave
               </Box>
            </Box>
          )
          }
          {isHalfDay && halfDate && (
             <Box sx={{width:"100%", display:"flex", justifyContent:"space-between"}}>
             <Box sx={{...leaveRecordStyles, flex:1}}>
                {halfLeaveList.map(date => dayjs(date).format("DD-MM-YYYY")).join(', ')}
             </Box>
             <Box sx={{...leaveRecordStyles}}>Half Leave</Box>
             <Box sx={{...leaveRecordStyles, flex:1, textAlign:"center"}}>
               {halfCount*0.5} leave
             </Box>
             </Box>
          )}
          </Box>

          <Box>
             <TextBox>Reason</TextBox>
             <TextField 
                id="reason" 
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={reason}
                onChange={(event) => setReason(event.target.value)}
                sx={{"& .MuiInputBase-root":InputBoxStyles,
                      "& .MuiOutlinedInput-notchedOutline":{
                          border:"none"}
              }} 
             />
          </Box>

      </DialogContent>
      <DialogActions sx={{alignSelf: 'center'}}>
        <BtnPrimary onClick={handleSubmit} sx={{ padding: '1rem 0.75rem', borderRadius: '4px', width: '18.75rem'}}>
             Apply Leave
        </BtnPrimary>
      </DialogActions>
    </Dialog>
  );
}
