import { postCall, getCall } from "./api";

 export const getAllDayAttendance = async () => {
    const request = {
        path: `/facial-recognition-attendance/days`,
    };
     return await getCall(request);
  };

  export const getAllDaySelfAttendance = async () => {
    const request = {
        path: `/facial-recognition-attendance/days/self`,
    };
     return await getCall(request);
  };

  export const getAllDayAttendanceByEmployeeId = async (employeeid) => {
    const request = {
        path: `/facial-recognition-attendance/days/${employeeid}`,
    };
     return await getCall(request);
  };

  export const getTodayDateString = async () => {
    const request = {
        path: `/facial-recognition-attendance/today`,
    };
     return await getCall(request);
  };

  export const getDayRecordByDate = async (dateString) => {
    const request = {
        path: `/facial-recognition-attendance/days/self/by-date/${dateString}`,
    };
     return await getCall(request);
  };

  export const markWebAttendance = async (input = {}) => {
    const request = {
      path: `/facial-recognition-attendance/self/web-attendance`,
      bodyParams: input,
    };
    return await postCall(request);
  };
  

  