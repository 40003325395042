import { postCall, getCall, putCall} from "./api";
import { getLegalUnitList } from "./globalData";

export const createEmployee = async (input = {}) => {
  const request = {
    path: `/employee/onboarding`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const getAllEmployee = async () => {
  const request = {
    path: `/employee`,
  };
  return await getCall(request);
};

export const fetchEmployeeDetailsById = async (employeeId) => {
  const request = {
    path: `/employee/${employeeId}`,
  };
  return await getCall(request);
};

export const updateEmployeeDetailsById = async (employeeId, input = {}) => {
  const request = {
    path: `/employee/${employeeId}/onboarding`,
    bodyParams: input,
  };
  return await putCall(request);
};

export const fetchEmployeesByIdArray = async (employeeIds) => {
  const request = {
    path: `/employee/id?employee=${employeeIds}`,
  };
  return await getCall(request);
};

export const sendEmployeeOnboardingEmail = async (employeeId) => {
  const request = {
    path: `/employee/${employeeId}/send-email`,
  };
  return await postCall(request);
};

export const addEmployeeToLegalUnit = async (employeeId) => {
  const legalUnit = getLegalUnitList();
  const legalUnitId = legalUnit[0]._id;
  const request = {
    path: `/legal-unit-employee/${legalUnitId}/employee`,
    bodyParams: {
      employeeId,
    },
  };
  return await postCall(request);
};

export const getEmployeesByDepartment = async (id) => {
  const request = {
    path: `/employee/by-department/${id}`,
  };
  return await getCall(request);
};

