import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import TopBar from '../../../../../../component/TopBar';
import MainBox from '../../../../container/MainBox';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import GeofenceDialog from '../../../../../../component/GeoFenceDialog';
import { getGeofence } from '../../../../../../services/geofence';
import GoogleMapWithPolyline from '../../../../../../component/GoogleMapPolyline';

const Geofence = () => {

 const [loading, setLoading] = useState(false);
 const [data, setData] = useState([]);
 const [isGeofenceDialogOpen, setIsGeofenceDialogOpen] = useState(false);
 const [startLocation,setStartLocation] = useState([]);
 const [endLocation, setEndLocation] = useState([]);
 const [middleLocations, setMiddleLocations] = useState([]);

 useEffect(() => {
  fetchGeofences();
 }, []);

 const fetchGeofences = async () => {
  setLoading(true);
  try{
    const getFences = await getGeofence();
    const fences = getFences?.data[0]?.geofenceObj?.fences[0];
    console.log(fences);
    
    console.log(fences.length);
    if (fences?.length > 0) {

      setStartLocation([{ latitude: fences[0].lat, longitude: fences[0].lng }]);
      setEndLocation([{ latitude: fences[0].lat, longitude: fences[0].lng }]);

      console.log(startLocation);
      console.log(endLocation);
    
      if (fences.length > 2) {
        const middle = fences?.slice(1, fences.length)?.map((fence) => ([{
          latitude: fence.lat,
          longitude: fence.lng
        }]));
        setMiddleLocations(middle);
      } else {
        setMiddleLocations([]);  
      }
      console.log(middleLocations);
    }
  }catch(error){
      console.log(error);
  }
 setLoading(false);
};

 return(
       <Box sx={{width:"100%", paddingBlock:"1rem"}}>
          <TopBar 
            MainHeading={"Geofence"} 
            breadcrumbs={[
              {label:"Settings"},
              {label:"Geofence"},
            ]}
          />
          <MainBox sx={{ padding: "2.5rem 1.25rem"}}>
              <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem", alignItems:"end" }}>
                    <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                       Geofence
                    </Box>
                    <BtnPrimary 
                      sx={{ height: '2.62rem', paddingInline:"1.25rem", borderRadius: '8px' }}
                      onClick={()=>setIsGeofenceDialogOpen(true)}
                    >
                        Add New Geofence
                    </BtnPrimary>
              </Box>
              {loading?(
                <Box sx={{width:"100%",display:"flex", justifyContent:"center"}}>
                  <CircularProgress/>
                </Box>
              ):(
              // console.log(startLocation, endLocation, middleLocations)
              startLocation.length === 0 ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "60vh" }}>
                  <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                    Geofence not added
                  </Box>
                </Box>
              ) : (
                <GoogleMapWithPolyline 
                  startLocation={startLocation}
                  endLocation={endLocation}
                  middleLocations={middleLocations}
                  defaultColor="#000000"
                  defaultZoom={10}
                  sx={{ width: "100%", height: "80vh", borderRadius: "12px" }}
                />
              )
            )}
          </MainBox>
          <GeofenceDialog 
             open={isGeofenceDialogOpen} 
             onClose={()=>setIsGeofenceDialogOpen(false)}
          />
       </Box>
 )
}

export default Geofence
