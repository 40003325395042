import { postCall, getCall, putCall } from "./api";

//Machine

export const addMachine = async (input = {}) => {
  const request = {
    path: `/asset/machine`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const getMachineData = async () => {
  const request = {
    path: `/asset/machines`,
  };
  return await getCall(request);
};

export const updateMachine = async (id, input = {}) => {
  const request = {
    path: `/asset/${id}/machine`,
    bodyParams: input,
  };
  return await putCall(request);
};

//Software

export const addSoftware = async (input = {}) => {
  const request = {
    path: `/asset/software`,
    bodyParams: input,
  };
  return await postCall(request);
};
  
export const getSoftwareData = async () => {
  const request = {
    path: `/asset/software`,
  };
  return await getCall(request);
};

export const updateSoftware = async (id, input = {}) => {
  const request = {
    path: `/asset/${id}/software`,
    bodyParams: input,
  };
  return await putCall(request);
};

