import React from "react";
import styled from "@emotion/styled";
import {Box} from "@mui/material";
import Profile from "./Profile";
import BreadCrumbsNavigation from "./BreadCrumbsNavigation";

const TopBarStyle = {
    display: "block",
    height: "5rem",
    width: "100%",
    marginBottom:"1.875rem",
    paddingBlock:"0.9375rem",
};

const TextBox = styled(Box)({
    display: "block",
    wordBreak: "break-word",
  });

const TopBar = ({MainHeading, breadcrumbs}) => (
  <Box sx={TopBarStyle}>
          <Box sx={{width:"100%", display:"flex", justifyContent:"space-between"}}>
            <Box>
                <TextBox 
                  fontSize={"1.25rem"} 
                  fontWeight={600}
                  lineHeight={"1.875rem"}
                  color="#16151C"
                >
                    {MainHeading||"Dashboard"}
                </TextBox>
                <BreadCrumbsNavigation breadcrumbs={breadcrumbs}/>
            </Box>
            <Box>
                 {/* <ToggleBellIcon/> */}
                 <Profile/>
             </Box>
          </Box>
  </Box>
);

export default TopBar;