import React,{useState, useEffect} from 'react';
import {Box,Autocomplete, TextField} from "@mui/material";
import styled from '@emotion/styled';
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import BtnPrimary from '../../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../../component/SecondaryButton';
import FormField from '../../../../../../../component/Formfield';
import DateField from '../../../../../../../component/DateField';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router';
import SaveDialog from '../../../../../../../component/SaveDialog';
import { updateEmployeeDetailsById } from '../../../../../../../services/employee';
import { getOrgDetails } from '../../../../../../../services/globalData';
import {getDepartments} from "../../../../../../../services/department";
import {countryList} from "../../../../../../../component/CountryList";

const TextBox = styled(Box)({
  display: 'block',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: '#818181',
});

const InputStyle = {
  height: '3rem',
  borderRadius: '10px',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight:"300",
  color: '#1E1E1E',
}

const EditDetails = () => {
  const navigate = useNavigate();
  const {employeeId}=useParams();
  const {state} = useLocation();
  const {data,headingText, name} = state;
  const [isDialogOpen,setIsDialogOpen] = useState(false);
  const [formData, setFormData] = useState(data.filter(item => item.field !== 'aliasId'));
  const [isDateDialogOpen,setIsDateDialogOpen] = useState(false);
  const [selectedDepartment,setSelectedDepartment] = useState({name:formData[4]?.value||""});
  const [selectedDesignation,setSelectedDesignation] = useState(formData[3]?.value||"");
  const [departments,setDepartments] = useState([]);
  console.log(formData);

  useEffect(()=>{
    fetchDepartments();
  },[])

  const fetchDepartments = async () => {
        try {
          const res = await getDepartments();
          console.log(res);
          const dept = res?.data?.map(department => ({
            name: department.name,
            id: department._id
          }));
          setDepartments(dept);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
  }

  const onDepartmentChange = (event,selectedValue) =>{
    console.log(selectedValue);
    setSelectedDepartment(selectedValue);
    handleChange("Department",selectedValue?.id);
  }

  const onDesignationChange = (event,selectedValue) =>{
    console.log(selectedValue);
    setSelectedDesignation(selectedValue);
    handleChange("Designation",selectedValue);
  }

  const handleChange = (label, newValue) => {
    setFormData(prevFormData => {
      const updatedFormData = prevFormData.map(item => {
        if (item.label === label) {
          return { ...item, value: newValue }; 
        }
        return item;
      });
      return updatedFormData;
    });
  };

  const transformedObject = formData.reduce((acc, item) => {
    acc[item.field] = item.value;
    return acc;
  }, {});

  const handleSave = async() =>{
    try{
      const res = await updateEmployeeDetailsById(employeeId,transformedObject);
      console.log(res);
      if(res.success){
        setIsDialogOpen(true);
      }
    }catch(error){
      console.log(error.response);
    }
  }

  return (
    <Box sx={{width:"100%"}}>
       <Topbar MainHeading={`${name}'s ${headingText}`} SubHeading="Edit Employee"/>        
       <MainBox sx={{padding:"1.5rem 1.25rem 1.875rem 1.25rem"}}>
           <Box sx={{fontWeight:"500",fontSize:"1.25rem",lineHeight:"1.75rem"}}>
             {headingText}
           </Box>
           <Box sx={{color:"#4B5563",fontSize:"0.875rem",lineHeight:"1.5rem",marginBottom:"1.5rem"}}>
             Employee Details 
           </Box>
           <Box sx={{width:"100%", display:"flex", flexWrap:"wrap", justifyContent:"space-between", }}>
               {
                  formData.map((item)=>{
                    return(
                      item.label.includes("Date")?(
                        <DateField
                          label={item.label}
                          value={item.value}
                          past={"enable"}
                          isDialogOpen={isDateDialogOpen}
                          openDialog={() => setIsDateDialogOpen(true)}
                          closeDialog={() => setIsDateDialogOpen(false)}
                          onSelect={(date)=> {
                                    handleChange(item.label, date);
                                    setIsDateDialogOpen(false)
                          }}
                        />
                      ):(
                        item.label.includes("Image")?(
                          <FormField
                              label={item.label}
                              type="file"
                              onChange={(e)=> handleChange(item.label,URL.createObjectURL(e.target.files[0]))}
                          />
                        ):(
                        item.field==="department"?(
                          <Box sx={{width:"48%"}}> 
                              <TextBox>Department</TextBox>
                              <Autocomplete
                                  options={departments}
                                  getOptionLabel={option => option?.name}
                                  size="small"
                                  value={selectedDepartment||null}
                                  onChange={onDepartmentChange}
                                  sx={{
                                    '& .MuiInputBase-root': InputStyle,
                                      "& .MuiOutlinedInput-notchedOutline":{
                                        border: "1px solid #E7E7ED",
                                      }
                                  }}
                                  renderInput={(params) => <TextField {...params}/>}
                              />
                          </Box>
                        ):(
                          item.field==="designation"?(
                            <Box sx={{width:"48%"}}> 
                                <TextBox>Designation</TextBox>
                                <Autocomplete
                                    options={getOrgDetails().designations}
                                    size="small"
                                    value={selectedDesignation||null}
                                    onChange={onDesignationChange}
                                    sx={{
                                      '& .MuiInputBase-root': InputStyle,
                                        "& .MuiOutlinedInput-notchedOutline":{
                                          border: "1px solid #E7E7ED",
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params}/>}
                                />
                            </Box>
                          ):(item.field==="nationality"?(
                            <Box sx={{width:"48%"}}> 
                                <TextBox>Nationality</TextBox>
                                <Autocomplete
                                    options={countryList}
                                    size="small"
                                    value={item.value||null}
                                    onChange={(event,selectedValue)=> handleChange(item.label,selectedValue)}
                                    sx={{
                                      '& .MuiInputBase-root': InputStyle,
                                        "& .MuiOutlinedInput-notchedOutline":{
                                          border: "1px solid #E7E7ED",
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params}/>}
                                />
                            </Box>
                          ):(
                            <FormField
                              label={item.label}
                              value={item.value}
                              type={item.options?"select":"text"}
                              options={item.options}
                              onChange={(e)=> handleChange(item.label,e.target.value)}
                            />
                        ))
                      )
                    )
                  )
                )
              })
            }
           </Box>
           <Box sx={{display:"flex", gap: "1.25rem", marginBlock: "1.875rem" }}>
                <BtnPrimary 
                 onClick={handleSave}
                 sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Save
                </BtnPrimary>
                <BtnSecondary 
                 onClick={() => navigate(-1)}
                 sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Cancel
                </BtnSecondary>
           </Box>
       </MainBox>
       <SaveDialog open={isDialogOpen} onClose={()=>setIsDialogOpen(false)} redirectLink={`/app/employee-details/${employeeId}`}/>
    </Box>
  ) 
}

export default EditDetails