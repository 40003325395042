import React, { useState, useEffect } from 'react';
import { Avatar, Box,Button,TableCell } from '@mui/material';
import PaginationTable from '../../../../../../component/PaginationTable';
import TopBar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import { getAllExpense} from '../../../../../../services/expense';
import { getEmployeeNames } from '../../../../../../utils/getEmployeeNames';
import Loader from '../../../../../../component/Loader';
import DateComponent from "../../../../../../component/DatePicker";
import CardItem from "../../../../../../component/CardItem";
import Upload from "../../../../../../assets/icons/upload.svg";
import Briefcase from "../../../../../../assets/icons/briefcase.svg";
import PieSegmentCircle from "../../../../../../assets/icons/chart-pie.svg";
import TV from "../../../../../../assets/icons/tv.svg";
import Expand from "../../../../../../assets/icons/expand.svg";
import ProgressBar from '../../../../../../component/ProgressBar';
import CSVButton from '../../../../../../component/CSVButton';
import { SearchBox } from '../../../../../../component/SearchBox';
import Department from '../../../../../../component/Department';
import { useNavigate } from 'react-router';
import PendingExpenseDialog from './PendingExpenseDialog';

const CustomTableCell = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis", 
  overflow:"hidden"
};


const Headings = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1.375rem 1rem",
  backgroundColor: "#F5F5F5",
  borderBottom: "none",
  textAlign:"center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

const ExpenseDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date().toLocaleDateString('en-GB')); 
    const [data, setData] = useState([]);
    const [filteredData,setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();
    const [approvedExpenses, setApprovedExpenses] = useState([]);
    const [approvedAmount, setApprovedAmount] = useState("");
    const [pendingExpenses, setPendingExpenses] = useState([]);
    const [pendingAmount, setPendingAmount] = useState("");
    const [isPendingExpenseDialogOpen, setIsPendingExpenseDialogOpen] = useState(false);

    useEffect(()=>{
      setFilteredData(data.filter((item)=>item.date===(new Date(date).toLocaleDateString('en-GB'))));
    },[date]);
    
    useEffect(()=>{
      setFilteredData(data.filter((item)=>(
        searchQuery===""?item:item?.employeeName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )));
    },[searchQuery]);

      const headings = [
        { label: "Date", style: {...Headings, width:"6.43rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
        { label: "Emp Name", style: {...Headings, width:"6.43rem", maxWidth: "6.43rem"} },
        { label: "Expense Id", style: {...Headings, width:"6.43rem"} },
        { label: "Category", style: {...Headings, width:"6.43rem"} },
        { label: "Amount", style: {...Headings, width:"6.43rem"} },
        { label: "Approval Status", style: { ...Headings, width:"6.43rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
      ];


      useEffect(()=>{
        const fetchData = async () => {
          try {
            const res = await getAllExpense();
            console.log(res);
            if(res.success){
              const employeeIds = res.data.map(data => data.submitterId);
              const combinedData = await getEmployeeNames(res.data, employeeIds, "submitterId");
              console.log(combinedData);
              const dataTable = combinedData?.filter(item => item.type === 'ANY')?.map((item)=>({
                 date:(new Date(item.createdAt).toLocaleDateString('en-GB')),
                 employeeName:item.employee.fname+" "+item.employee.lname,
                 category:item.type,
                 expenseId:item._id,
                 amount:item.amount,
                 status:item.status,
                 _id:item._id
              }));
              const reversedDataTable = dataTable.reverse();
              setData(reversedDataTable);
              setFilteredData([...reversedDataTable]);

              const pendingExpenses = reversedDataTable.filter((item) => item.status === 'pending');
              const approvedExpenses = reversedDataTable.filter((item) => item.status === 'approved');
        
              const pendingAmount = pendingExpenses.reduce((total, item) => total + (parseFloat(item.amount) || 0), 0);
              const approvedAmount = approvedExpenses.reduce((total, item) => total + (parseFloat(item.amount) || 0), 0);
        
              setPendingExpenses(pendingExpenses);
              setPendingAmount(pendingAmount);
              setApprovedExpenses(approvedExpenses);
              setApprovedAmount(approvedAmount);

              setLoading(false);
          }
        }catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
      
        fetchData();
      },[])

    return (
        <Box sx={{ width: "100%", paddingBlock:"1rem"}}>
            <TopBar MainHeading={"Expenses"} SubHeading={"Expenses"}/>
            <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
            {loading?(
              <Loader/>
            ):(
             <Box>
              <Box>  
                  <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1rem", alignItems:"end" }}>
                      <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                          Dashboard
                      </Box>
                      <DateComponent value={date} onDateSelect={(date)=>setDate(date)} styles={{}}/> 
                  </Box>
                  <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", gap: "1.75rem", marginBottom:"1rem"}}>
                        <CardItem icon={Upload} Heading={"Pending for Verfication"} sx={{BorderColor:"#D62525"}}>
                          <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                              {pendingAmount === '' ? '--' : "₹ "+pendingAmount}
                          </Box>
                          <Box sx={{display:"flex", justifyContent:"space-between"}}>
                              <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem", color:"#D62525"}}>
                                  Update: {new Date().toLocaleDateString('en-GB')}
                              </Box>
                              <Button 
                                sx={{textTransform:"none", color:"#0081FF", textDecoration:"underline"}}
                                onClick={()=>setIsPendingExpenseDialogOpen(true)}
                              >
                                Review
                              </Button>
                          </Box>
                        </CardItem>
                        <CardItem icon={Briefcase} Heading={"Pending for approval"}>
                          <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                              {"--"}
                          </Box>
                          <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem", color:"#FFA800"}}>
                               Update: {new Date().toLocaleDateString('en-GB')}
                          </Box>
                        </CardItem>
                        <CardItem icon={PieSegmentCircle} Heading={"Claims Approved"}>
                          <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                              {approvedAmount === '' ? '--' : "₹ "+approvedAmount}
                          </Box>
                          <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem", color:"#069855"}}>
                                Update: {new Date().toLocaleDateString('en-GB')}
                          </Box>
                        </CardItem>
                  </Box>
                </Box>
                <MainBox sx={{ padding: "1.5rem" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.9375rem", alignItems:"end" }}>
                            <Box>
                                <Box sx={{display:"flex", gap:"0.625rem"}}>
                                  <Avatar src={TV} style={{ width: '1.5rem', height: '1.5rem' }}/>
                                  <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                                       Expense Summary
                                  </Box>
                                </Box>
                                <ProgressBar value={100}/>
                            </Box>
                            <Avatar 
                              src={Expand} 
                              style={{ width: '1.5rem', height: '1.5rem', cursor:"pointer"}}
                              onClick={() => navigate("/app/expense-requests",{state:{data:data}})}
                            />
                    </Box>
                    <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBlock:"1.5rem"}}>
                        <SearchBox 
                            styles={{height:"2.25rem", width:"16.625rem"}} 
                            searchQuery={searchQuery} 
                            handleSearch={(value)=>setSearchQuery(value)}
                            placeholder={"Search by location, date"}
                        />   
                        <Department sx={{height:"2.5rem"}}/>
                        <DateComponent value={date} onDateSelect={(date) => setDate(date)} styles={{height:"2.25rem"}}/> 
                        <CSVButton data={data} styles={{padding: '1rem 1.5rem', height:"2.25rem", width:"10rem", borderRadius: '10px'}}/>
                    </Box>
                    <PaginationTable
                        rows={filteredData}
                        headings={headings}
                        loading={loading}
                        noRecordMessage={"No Record Found"}
                        paginationHide={true}
                        link={true}
                        path="/app/expense-requests-details"
                        renderCell={(row) => (
                          <>
                            <TableCell 
                              align="center"
                              sx={{...CustomTableCell, width:"6.43rem",  maxWidth:"6.43rem"}}
                              >
                                {row.date}
                              </TableCell>
                            <TableCell 
                              align="center"
                              sx={{...CustomTableCell, width:"9rem", maxWidth: "9rem"}}
                            >
                                {row.employeeName}
                            </TableCell>
                            <TableCell 
                              align="center"
                              sx={{...CustomTableCell, width:"6.43rem", maxWidth: "6.43rem"}} 
                            >
                                {row.expenseId}
                            </TableCell>
                            <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>{row.category}</TableCell>
                            <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>{row.amount}</TableCell>
                            <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>
                                    <Box sx={{
                                              borderRadius:"4px", 
                                              padding:"4px 8px",
                                              textAlign:"center",
                                              backgroundColor: row.status === "pending" ? "#FFBE64" : (row.status === "rejected" ? "#E493931A" : "#0CBD7D"),
                                              color: row.status === "pending" ? "#212423" : (row.status === "rejected" ? "#E52E2E" : "#FFFFFF")
                                            }}>
                                        {row.status}
                                    </Box>
                            </TableCell>
                          </>
                        )}
                    />
                </MainBox>
              </Box>
            )}
            </MainBox>
            <PendingExpenseDialog
              open={isPendingExpenseDialogOpen} 
              onClose={setIsPendingExpenseDialogOpen} 
              data={pendingExpenses}
              pendingAmount={pendingAmount}
            />
        </Box>
    )
}

export default ExpenseDashboard;
