import React, { useEffect, useState } from "react";
import {Avatar, Box, CircularProgress} from "@mui/material";
import TopBar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import BtnSecondary from "../../../../../../../component/SecondaryButton";
import { PolicyTableHeadings} from '../../../../../../../styles/PolicyTableHeadings';
import { PolicyTableCell} from '../../../../../../../styles/PolicyTableCell';
import PaginationTable from "../../../../../../../component/PaginationTable";
import CurrentDateTime from "../../../../../../../component/CurrentDateTime";
import { getAllDayAttendanceByEmployeeId } from "../../../../../../../services/facialRecognition";
import { useParams } from "react-router";
import { getOrgEmployees } from "../../../../../../../services/globalData";
import { getDuration } from "../../../../../../../utils/getDuration";
import { getGeofence } from "../../../../../../../services/geofence";
import { isPointInFence } from "../../../../../../../utils/isPointInFence";
import { calculateTime } from "../../../../../../../utils/calculateTime";
import { formatTimeString } from "../../../../../../../utils/formatTimeString";
import Loader from "../../../../../../../component/Loader";
import { SearchBox } from "../../../../../../../component/SearchBox";
import DateComponent from "../../../../../../../component/DatePicker";
import CSVButton from "../../../../../../../component/CSVButton";


export default function EmployeeAttendance(){
  
  const [loading,setLoading] = useState(false);
  const [data, setData] = useState([]);
  const {employeeId} = useParams();
  const employeeDetails = getOrgEmployees()?.find((emp)=> emp?._id===employeeId);
  const [fence,setFence] = useState();
  const [currentDayStatus, setCurrentDayStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState();  
  const [activeTab, setActiveTab] = useState("Daily");
  console.log(employeeId);
  console.log(employeeDetails);

  useEffect(()=>{
    fetchGeofences();
    fetchEmployeeAttendance();
},[])

const fetchEmployeeAttendance = async() =>{
    setLoading(true);
    try{
      const res = await getAllDayAttendanceByEmployeeId(employeeId);
      setData(res?.data.reverse());
      console.log(res);
    }catch(e){
      console.log(e);
    }
    setLoading(false);
}

const fetchGeofences = async () => {
  const getFences = await getGeofence();
  const fences = getFences?.data[0]?.geofenceObj?.fences[0];
  setFence(fences);
};

console.log(isPointInFence(fence, "28.5109499", "77.0719872"));

  const headings = [
    { label: "Date", style: {...PolicyTableHeadings, width:"4.25rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Status", style: {...PolicyTableHeadings,  width:"7.5rem"} },
    { label: "Location", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Clock In", style: {...PolicyTableHeadings, width:"8.75rem"} },
    { label: "Clock Out", style: {...PolicyTableHeadings, width:"8.75rem"} },
    { label: "Break Time", style: {...PolicyTableHeadings, width:"8.75rem"} },
    { label: "Total Time", style: {...PolicyTableHeadings, width:"8.75rem"} },
    { label: "Effective Hrs", style: { ...PolicyTableHeadings,width:"8.75rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
  ];

  return (
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Attendance"} 
          breadcrumbs={[
            {label:"Attendance"},
            {label:"Dashboard", href:"/app/attendance-dashboard"},
            {label:"Employee Detail"}
          ]}
        />
        <MainBox sx={{ padding: "2.5rem 1.25rem"}}>
        {loading?(
          <Loader/>
        ):(
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.96rem", alignItems:"end" }}>
                <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                   Attendance Details
                </Box>
                <CurrentDateTime/>
            </Box>
            <Box 
              sx={{
                display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:"1.5rem",fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#16151C",
                borderBottom: "0.5px solid #16151C33", paddingBlock:"1rem"   
              }}
            >
              <Box sx={{display:"flex", gap:"0.5rem", alignItems:"center"}}>
                <Avatar sx={{height:"2.5rem", width:"2.5rem"}}/>
                <Box>
                  {employeeDetails?.fname+" "+employeeDetails?.lname}
                </Box>
              </Box>
              <Box> {employeeDetails?.aliasId} | Status: </Box>
              <BtnSecondary sx={{ width: '5.56rem', height: '2rem', borderRadius: '8px' }} to="/app/attendance-dashboard">
                  Home
              </BtnSecondary>
            </Box>
            <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBlock:"1.5rem"}}>
               <SearchBox 
                  styles={{height:"2.25rem", width:"16.625rem"}} 
                  searchQuery={searchQuery} 
                  handleSearch={(value)=>setSearchQuery(value)}
                  placeholder={"Search by location, date"}
              />
              <Box sx={{display:"flex",gap:"1rem"}}>
                 <DateComponent value={selectedDate} onDateSelect={(date) => setSelectedDate(date)} styles={{height:"2.25rem"}}/> 
                 <Box sx={{display:"flex", width:"21.31rem", height:"2.25rem", border:"0.5px solid #16151C33", borderRadius:"10px"}}>
                            {["Monthly","Weekly","Daily"].map((tabItem)=>(
                              <Box
                                key={tabItem}
                                onClick={() => setActiveTab(tabItem)}
                                sx={{
                                  flex:"1",
                                  fontSize:"1rem",
                                  lineHeight:"1.5rem",
                                  fontWeight: activeTab === tabItem ? "500" : "300",
                                  cursor:"pointer",
                                  color: activeTab === tabItem ? "#0081FF" : "#14141480",
                                  borderRight:"1px solid #A2A1A833",
                                  textDecoration:  activeTab === tabItem ? "underline" : "none",
                                  display: "flex",           
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}
                            >
                              {tabItem}
                            </Box>
                            ))}
                 </Box>
                 <CSVButton data={data} styles={{padding: '1rem 1.5rem', height:"2.25rem", width:"10rem", borderRadius: '10px'}}/>
              </Box>
            </Box>
             <PaginationTable
                rows={data}
                headings={headings}
                loading={loading}
                noRecordMessage={"No Record Found"}
                renderCell={(row) => (
                  <>
                    <PolicyTableCell>{row?.dayString}</PolicyTableCell>
                    <PolicyTableCell >{"Present"}</PolicyTableCell>
                    <PolicyTableCell>
                      {isPointInFence(fence, row?.arrivalLat, row?.arrivalLong)?"In Office":"Remote"}
                    </PolicyTableCell>
                    <PolicyTableCell>{formatTimeString(row?.createdAt)}</PolicyTableCell>
                    <PolicyTableCell>{!isNaN(Number(row?.departureTime)) ? calculateTime(row?.departureTime) : "--"}</PolicyTableCell>
                    <PolicyTableCell>{getDuration(row?.breakHours)}</PolicyTableCell>
                    <PolicyTableCell>{getDuration(row?.totalHours)}</PolicyTableCell>
                    <PolicyTableCell>{getDuration(row?.effectiveHours)}</PolicyTableCell>
                  </>
                )}
              />
            </Box>
          )}
        </MainBox>
     </Box>
  );
};
