import React, { useEffect, useState } from "react";
import {Avatar, Box, Button, CircularProgress} from "@mui/material";
import Topbar from "../../../../../component/TopBar";
import styled from "@emotion/styled";
import CalenderIcon from "../../../../../assets/icons/calendar-dot.svg";
import Card from "../../../container/Card";
import {AvatarWrapper, AvatarImage} from "../../../container/Avatar";
import { getOnLoginEmployeeInfo } from '../../../../../services/globalData';
import { currentDate } from "../../../../../utils/currentDate";
import BtnPrimary from '../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../component/SecondaryButton';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useNavigate } from "react-router";
import { getSelfAttendancePolicy } from "../../../../../services/attendancePolicy";
import { getDayRecordByDate, getTodayDateString, markWebAttendance } from "../../../../../services/facialRecognition";
import { currentDay } from "../../../../../utils/currentDay";
import CupBlue from "../../../../../assets/icons/cup-blue.svg";
import CheckInHand from "../../../../../assets/icons/check-in-hand-white.svg";
import CheckInHandBlue from "../../../../../assets/icons/check-in-hand-blue.svg";
import Loader from "../../../../../component/Loader";
import { calculateTime } from "../../../../../utils/calculateTime";

const TextBox = styled(Box)({
  display: "block",
  whitespace:"nowrap",
});

const commonBoxStyle = {
  height:"8.75rem",
  borderRadius:"8px",
  padding:"0.75rem",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  color:"#FFF",
  fontSize:"1rem",
  fontWeight:"500",
  lineHeight:"1.25rem",
  cursor:"pointer"
}

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [inTime, setInTime] = useState(null);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [dayRecords, setDayRecords] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
     fetchUserDetails();
  },[])

  useEffect(()=>{
    getAttendanceRecords();
  },[isCheckedIn])

  const getAttendanceRecords = async() =>{
    setLoading(true);
    const attendancePolicyRes = await getSelfAttendancePolicy();
    console.log(attendancePolicyRes);

    const dateStringRes = await getTodayDateString();
  
    const dayRecordRes = await getDayRecordByDate(dateStringRes?.data?.dateString);
    console.log(dayRecordRes);
    if(dayRecordRes?.data)
    {
      setDayRecords(dayRecordRes?.data);
      setInTime(dayRecordRes?.data?.createdAt);
      if(!dayRecordRes?.data?.departureTime)
         setIsCheckedIn(true);
    }
    setLoading(false);
  }

  const checkIn = async() =>{
      getAttendanceRecords();  
      const res = await markWebAttendance({type:"IN"});
      console.log(res);
      setIsCheckedIn(true);
  }

  const checkOut = async() =>{
      const res = await markWebAttendance({type:"OUT"});
      console.log(res);
      setIsCheckedIn(false);
  }

  const fetchUserDetails = async() =>{
    const employeeInfo = getOnLoginEmployeeInfo();
    setUser(employeeInfo.employee);
  }

  return (
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
      <Topbar/>
      <Box sx={{width:"100%", display:"flex", justifyContent:"center", marginBottom:"1.5rem"}}>
           <Box sx={{fontWeight:"500", fontSize:"1.75rem", lineHeight:"2.625rem"}}>
             Good to see you, {user?.fname+" "+user?.lname} 👋
           </Box>
           <Box></Box>
      </Box>
      <Box sx={{width:"100%", display:"flex", gap:"1rem", marginBottom:"1.5rem"}}>
        <Card sx={{flex:1, padding:"0.75rem"}}>
             <Box sx={{display:"flex", justifyContent:"space-between", paddingBlock:"0.625rem"}}>
                 <Box sx={{color:"#0A0A0A",fontSize: '1.25rem',fontWeight: 600,lineHeight: '2rem',letterSpacing: '-0.02em',}}>Daily Hours Log</Box>
                 <Box sx={{color:"#5C5C5C",fontSize: '0.875rem',lineHeight: '1.3125rem'}}>
                  {currentDate()}
                 </Box>
             </Box>
             <Box sx={{paddingBlock:"0.75rem", display:"flex", justifyContent:"space-between", borderBlock:"1px solid #E5E5E5"}}>
                 <Box>
                     <Box sx={{color:"#2B3674",fontSize: '1.25rem',fontWeight: 700,lineHeight:'2rem',letterSpacing: '-0.02em'}}>
                        Punch In at
                     </Box>
                     <Box sx={{color:"#A3AED0",fontSize: '0.875rem',fontWeight: 500,lineHeight:'1.5rem',letterSpacing: '-0.02em'}}>
                        {
                          `${currentDay()} In Time: ${inTime && new Date(inTime)?.toLocaleString(undefined, {
                              hour: '2-digit',
                              minute: '2-digit',
                            })|| "--"}`
                        }
                     </Box>
                 </Box>
             </Box>
             {loading?
                <Box sx={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                   <CircularProgress/>
                </Box>
              :(
             <Box sx={{display:"flex", flexDirection:"column", gap:"1.125rem", marginBlock:"0.625rem", justifyContent:"center", alignItems:"center", paddingBlock:"1.25rem"}}>
                 {inTime ?
                 ( isCheckedIn ?(
                   <Box sx={{position:"relative", display:"inline-flex"}}>
                      <CircularProgress
                        variant="determinate"
                        value={100}
                        size="10.625rem"
                        thickness={3}
                        sx={{color: '#E3E7E5', position: 'absolute',left: 0,}}
                      />
                      <CircularProgress 
                        variant="determinate" 
                        value={25} 
                        size="10.625rem" 
                        thickness={3}
                        sx={{color: '#03B858', 
                          '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round',
                          },
                        }}
                      />
                      <Box
                        sx=
                        {{top:0, left:0, bottom:0, right:0, position:"absolute", display:"flex", alignItems:"center", justifyContent:"center",
                          fontWeight:"500", color:"#2B3674", fontSize:"1rem", lineHeight:"1.5rem"
                        }}
                      >
                          Checked In
                      </Box>
                      <Button
                        sx={{
                          position: 'absolute',
                          bottom:'10px', 
                          right: '-15px', 
                          borderRadius: '50%',
                          minWidth: '4.25rem',
                          minHeight: '4.25rem',
                          backgroundColor:"white",
                          border:"1px solid #0063F5",
                          ":hover":{
                            backgroundColor:"white",
                            boxShadow:"0px 4px 40px 0px #0000001A",
                          }
                        }}
                        onClick={() => checkOut()} 
                      >
                        <Avatar src={CupBlue} alt="break-btn"/> 
                      </Button>
                   </Box>
                 ):(
                  <Box>
                    <Box sx={{position:"relative", display:"inline-flex", height:"11.125rem", width:"11.125rem",borderRadius:"50%", background:"linear-gradient(180deg, #F8E4B1 0%, #FDCE71 47.94%, #E2A302 100%)"}}>
                        <Box
                          sx=
                          {{top:0, left:0, bottom:0, right:0, position:"absolute", display:"flex", alignItems:"center", justifyContent:"center",
                            fontWeight:"600", color:"#FFFFFF", fontSize:"1.25rem", lineHeight:"2rem"
                          }}
                        >
                          Break Time
                        </Box>
                        <Button
                          sx={{
                            position: 'absolute',
                            bottom:'10px', 
                            right: '-15px', 
                            borderRadius: '50%',
                            minWidth: '4.25rem',
                            minHeight: '4.25rem',
                            backgroundColor:"white",
                            border:"1px solid #0063F5",
                            ":hover":{
                              backgroundColor:"white",
                              boxShadow:"0px 4px 40px 0px #0000001A",
                            }
                          }}
                          onClick={() => checkIn()} 
                        >
                          <Avatar src={CheckInHandBlue} alt="check-in-btn"/> 
                        </Button>
                    </Box>
                    <Box sx={{display:"flex", alignItems:"center", fontSize:"0.875rem", lineHeight:"1.3125rem"}}>
                       Break Started From: {calculateTime(dayRecords?.departureTime)}
                     </Box>
                  </Box>
                 )
                 )
                 :(
                  <Button 
                      sx={{
                        height:"10.625rem", 
                        width:"10.625rem",
                        borderRadius:"50%",
                        textTransform:"none",
                        boxShadow:"0px 4px 40px 0px #0000001A",
                        background: "linear-gradient(180deg, #BEDCF9 0%, #7EBCF8 47.94%, #0473E0 100%)",
                        color:"white",
                        fontSize:"1.155rem",
                        lineHeight:"1.7325rem",
                        fontWeight:"600",
                        display: "flex",
                        flexDirection: "column", 
                        alignItems: "center", 
                        justifyContent: "center"
                      }}
                      onClick={() => checkIn()} 
                  > 
                      <Avatar src={CheckInHand} alt="break-btn"/>
                      <Box>Check In</Box> 
                  </Button>
                 )}
             </Box>
             )}
             <Box sx={{display:"flex", justifyContent:"center", gap:"2rem", alignItems:"center", paddingBlock:"1.25rem"}}>
                  <BtnPrimary 
                    sx={{padding:"0.5rem 1rem", borderRadius:"4px", backgroundColor:"#0053F0", width:"11.5rem"}}
                    to="/app/attendance-details/my-attendance"
                  >
                    My Attendance
                  </BtnPrimary>
                  {/* {inTime && 
                   <BtnSecondary 
                      sx={{padding:"0.5rem 1rem", borderRadius:"4px", width:"11.5rem"}}
                      onClick={()=> checkOut()}
                    >
                      Punch Out
                    </BtnSecondary>
                  } */}
             </Box>
             <Box sx={{display:"flex", justifyContent:"space-between", borderTop:"1px solid #E5E5E5"}}>
                 <Box sx={{flex:1, borderRight:"1px solid #E5E5E5", padding:"0.75rem 1.5rem"}}>
                     <Box sx={{color:"#2B3674", textAlign:"center",fontSize: '1.25rem',fontWeight: 700,lineHeight:'2rem',letterSpacing: '-0.02em'}}>
                      Current Shift 
                     </Box>
                     <Box sx={{color:"#A3AED0", textAlign:"center",fontSize: '0.875rem',fontWeight: 500,lineHeight:'1.5rem',letterSpacing: '-0.02em'}}>
                      --
                     </Box>
                 </Box>
                 <Box sx={{flex:1, padding:"0.75rem 1.5rem"}}>
                     <Box sx={{color:"#2B3674", textAlign:"center",fontSize: '1.25rem',fontWeight: 700,lineHeight:'2rem',letterSpacing: '-0.02em'}}>
                      Current Status 
                     </Box>
                     <Box sx={{color:"#A3AED0", textAlign:"center",fontSize: '0.875rem',fontWeight: 500,lineHeight:'1.5rem',letterSpacing: '-0.02em'}}>
                      --
                     </Box>
                 </Box>
             </Box>
        </Card>
        <Card sx={{flex:1, padding: "1.25rem"}}>
          <Box sx={{display:"flex", gap:"1.375rem"}}>
              <Box sx={{flex:1}}>
                    <TextBox fontSize="1.25rem" fontWeight={600} lineHeight="1.875rem" color="#16151C">Schedule</TextBox>
                  
              </Box>
              <Box sx={{flex:1, display:"flex", flexDirection:"column", gap:"0.75rem"}}>
                    <Box sx={{...commonBoxStyle, backgroundColor:"#0081FF"}} onClick={()=>{navigate("/app/settings/payroll/dashboard")}}>
                        Payroll Management
                    </Box>
                    <Box sx={{...commonBoxStyle, backgroundColor:"#FFA800"}} onClick={()=>{navigate("/app/leave-dashboard")}}>
                        Leave Management
                    </Box>
                    <Box sx={{...commonBoxStyle, backgroundColor:"#FB662B"}} onClick={()=>{navigate("/app/expense-dashboard")}}>
                        Expenses Management
                    </Box>
              </Box>
          </Box>
        </Card>       
      </Box>
      <Box sx={{width:"100%", display:"flex", gap:"1rem", marginBottom:"1.5rem"}}>
        <Card sx={{flex:1,padding:"0.75rem"}}>

        </Card>
        <Card sx={{flex:1, padding: "1.25rem"}}>
           <Box sx={{display:"flex", justifyContent:"space-between"}}>
                    <TextBox fontSize="1.25rem" fontWeight={600} lineHeight="1.875rem" color="#16151C">Notifications</TextBox>
                    <AvatarWrapper sx={{backgroundColor:"#7152F31A", border:"none"}}>
                       <AvatarImage src={CalenderIcon}/>
                    </AvatarWrapper>
           </Box>
         
        </Card>       
      </Box>
    </Box>
   )
};

export default Dashboard