import React, { useState } from 'react';
import dayjs from 'dayjs';
import ArrowLeftIcon from '../assets/icons/left-arrow.svg';
import ArrowRightIcon from '../assets/icons/right-arrow.svg';
import {Box, IconButton} from '@mui/material';

const MonthPicker = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handlePreviousMonth = () => {
    setSelectedDate(selectedDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedDate(selectedDate.add(1, 'month'));
  };

  return (
    <Box sx={{width:"100%", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
      <IconButton onClick={handlePreviousMonth}>
        <img src={ArrowLeftIcon} sx={{height:"0.5rem", width:"0.5rem"}} alt="previous"/>      
      </IconButton>
      <Box sx={{fontSize:"1.125rem", lineHeight:"1.5rem"}}>
        {selectedDate.format('MMMM YYYY')}
      </Box>
      <IconButton onClick={handleNextMonth}>
         <img src={ArrowRightIcon} sx={{height:"0.5rem", width:"0.5rem"}} alt="next"/>      
      </IconButton>
    </Box>
  );
};

export default MonthPicker;
