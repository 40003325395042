import React, { useState, useEffect } from 'react';
import { Collapse, List, ListItemButton, ListItemText, Checkbox, FormControl, OutlinedInput, Box, Chip,} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BtnPrimary from "./PrimaryButton";

export default function CollapsibleSelection({optionList, onSelect,  selectedValues }) {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (selectedValues !== undefined) {
      setSelectedOptions(selectedValues);
    } else {
      setSelectedOptions([]);
    }
  }, [selectedValues]);


  const handleToggle = () => {
    setOpen(!open);
  };

  const handleOptionSelect = (option) => {
    const selectedIndex = selectedOptions.findIndex((item) => item.value === option.value);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedOptions, option];
    } else {
      newSelected = selectedOptions.filter((item) => item.value !== option.value);
    }
    setSelectedOptions(newSelected);
  };

  const handleAdd = () =>{
     onSelect(selectedOptions);
     setOpen(!open);
  }

  const handleChipDelete = (chipToDelete) => () => {
    const newSelectedOptions = selectedOptions.filter((option) => option !== chipToDelete);
    setSelectedOptions(newSelectedOptions);
    onSelect(newSelectedOptions);
  };

  const formatValue = () => {
    console.log(selectedOptions);
    if (!selectedOptions || selectedOptions.length === 0) {
      return null;
    }

    return selectedOptions.map(option => (
      <Chip 
        key={option.name} 
        label={option.name} 
        onDelete={handleChipDelete(option)}
        variant="outlined"
        sx={{ backgroundColor: '#F3F4F6', marginRight: '0.5rem' }}
      />
    ));
  };

  console.log(selectedOptions);


  return (
    <div>
      <FormControl sx={{width: '100%', borderRadius:"10px",}}>
       <OutlinedInput
          onClick={handleToggle}
          readOnly
          id="select-multiple-checkbox"
          endAdornment={<ExpandMoreIcon />}
          sx={{cursor: 'pointer', height:"3rem" }}
          renderValue={formatValue}
        />
        {formatValue()}
        <Collapse in={open}>
          <List>
            {optionList.map((legalUnit) => (
              <ListItemButton key={legalUnit.value} onClick={() => handleOptionSelect(legalUnit)} sx={{marginBottom:"0.75rem", border: "1px solid #B3B3B333", borderRdaius:"4px"}}>
                <Checkbox
                  checked={selectedOptions.findIndex(option => option.value === legalUnit.value) !== -1}                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={legalUnit.name} sx={{fontSize:"1rem", lineHeight:"1.5rem", color:"#3A3A3A"}}/>
              </ListItemButton>
            ))}
          </List>
          <Box sx={{display:"flex", justifyContent:"end"}}>
                <BtnPrimary onClick={()=> handleAdd()} sx={{width:"5rem", height:"2.37rem", borderRadius:"8px"}}>
                    ADD
                </BtnPrimary>
          </Box>
        </Collapse>
      </FormControl>
    </div>
  );
}
