export const setOrgDetails = (data) => {
  const employees = JSON.stringify(data);
  sessionStorage.setItem("sty-user-org", employees);
};

export const getOrgDetails = () => {
  const data = sessionStorage.getItem("sty-user-org");
  return data ? JSON.parse(data) : null;
};

let onLoginEmployeeInfo;
export const setOnLoginEmployeeInfo = (data) => (onLoginEmployeeInfo = data);
export const getOnLoginEmployeeInfo = () => onLoginEmployeeInfo;

export const getLegalUnitList = () => {
  const list = sessionStorage.getItem("sty-legal-unit");
  return JSON.parse(list);
};
export const setLegalUnitList = (list) => {
  const stringifyList = JSON.stringify(list);
  sessionStorage.setItem("sty-legal-unit", stringifyList);
};

let activeLegalUnit;
export const getActiveLegalUnit = () => activeLegalUnit;
export const setActiveLegalUnit = (list) => (activeLegalUnit = list);

let geofencesList;
// export const getGeofenceList = () => geofencesList;
// export const setGeofenceList = (list) => (geofencesList = list);

export const getGeofenceList = () => {
  const list = sessionStorage.getItem("geofence");
  return JSON.parse(list);
};
export const setGeofenceList = (list) => {
  const stringifyList = JSON.stringify(list);
  sessionStorage.setItem("geofence", stringifyList);
};

export const setOrgEmployees = (data) => {
  const employees = JSON.stringify(data);
  sessionStorage.setItem("org-employees", employees);
};

export const getOrgEmployees = () => {
  const data = sessionStorage.getItem("org-employees");
  return data ? JSON.parse(data) : null;
};

export const setOrgDepartments = (data) => {
  const departments = JSON.stringify(data);
  sessionStorage.setItem("org-departments", departments);
};

export const getOrgDepartments = () => {
  const data = sessionStorage.getItem("org-departments");
  return data ? JSON.parse(data) : null;
};

export const setOrgGroups = (data) => {
  const groups = JSON.stringify(data);
  sessionStorage.setItem("org-groups", groups);
};

export const getOrgGroups = () => {
  const data = sessionStorage.getItem("org-groups");
  return data ? JSON.parse(data) : null;
};

export const setOrgShifts = (data) => {
  const shifts = JSON.stringify(data);
  sessionStorage.setItem("org-shifts", shifts);
};

export const getOrgShifts = () => {
  const data = sessionStorage.getItem("org-shifts");
  return data ? JSON.parse(data) : null;
};