import React, {useState, useEffect} from 'react'
import styled from "@emotion/styled";
import {Box} from '@mui/material';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import ActionDialog from '../../../../../../component/ActionDialog';
import {approveExpense, getExpenseById, rejectExpense } from '../../../../../../services/expense';
import { getOrgEmployees } from '../../../../../../services/globalData';
import Topbar from "../../../../../../component/TopBar";
import MainBox from '../../../../container/MainBox';
import Loader from '../../../../../../component/Loader';
import FormField from '../../../../../../component/Formfield';
import { useParams } from 'react-router';

const ExpenseRequestDetails = () => {

const [loading,setLoading] = useState(false);
const [expenseData, setExpenseData] = useState();
const [employee,setEmployee] = useState();
const {id} = useParams();
const [approvers, setApprovers] = useState([]);
 const [approveDialogOpen, setApproveDialogOpen] = useState(false);
 const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
 const [refresh, setRefresh] = useState(false);

 useEffect(() => {
    fetchExpenseDetails();
 }, [refresh]);

const fetchExpenseDetails = async () => {
    setLoading(true);
    try {
      const res = await getExpenseById(id); 
      console.log(res); 
      setEmployee(getOrgEmployees()?.find(employee => employee?._id=== res?.data?.submitterId));
      setExpenseData(res?.data);
      setApprovers(getOrgEmployees().filter(emp => res?.data?.policy?.approverIds.includes(emp._id)));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const handleReject = () => {
    setRejectDialogOpen(true);
  };

  const handleApprove = async() => {
    setApproveDialogOpen(true);
  };

  const handleCloseRejectDialog = () => {
    setRejectDialogOpen(false);
    setRefresh((prev) => !prev);
  };
  
  const handleCloseApproveDialog = () => {
    setApproveDialogOpen(false);
    setRefresh((prev) => !prev);
  };

  console.log(approvers);
  return (
    <Box sx={{ width: "100%", paddingBlock:"1rem"}}>
            <Topbar 
              MainHeading={"Expense"}
              breadcrumbs={[{label:"Expense", href:""},{label:"Dashboard", href:"/app/expense-dashboard"},{label:"Requests", href:""}]}
            />
          {loading?
            <Loader/>
          :
          <MainBox sx={{ padding: "2.62rem 1.875rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem"}}>
              <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                  Request Details
              </Box>
              {new Date().toLocaleDateString("en-US", {day: "numeric",month: "long",year: "numeric"})}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"3.68rem", borderBottom:"0.5px solid #16151C33", padding:"1.25rem"}}>
              <Box>{employee?.fname+" "+employee?.lname}</Box>
              <Box>{employee?.aliasId}</Box>
              <Box sx={{
                        alignSelf:"end",
                        borderRadius:"4px", 
                        padding:"2px 8px",
                        textAlign:"center",
                        backgroundColor: expenseData?.status === "pending" ? "#FFBE64" : (expenseData?.status === "rejected" ? "#E493931A" : "#0CBD7D"),
                        color: expenseData?.status === "pending" ? "#212423" : (expenseData?.status === "rejected" ? "#E52E2E" : "#FFFFFF")
                      }}>
                  {expenseData?.status}
              </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between",gap:"1rem",marginBottom:"3.68rem"}}>
              <Box sx={{flex:1, display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
                  <FormField
                      label="Expense Type"
                      value={expenseData?.policyCateogary?.name}
                      disabled={true}
                  />
                  <FormField
                      label="Request Id"
                      value={expenseData?._id}
                      disabled={true}
                  />
                  <FormField
                      label="Amount"
                      value={expenseData?.amount}
                      disabled={true}
                  />
                  <FormField
                      label="Date & Time"
                      value={new Date(expenseData?.createdDate).toLocaleString('en-GB')}
                      disabled={true}
                  />
                  <FormField
                      label="Description & Attachment"
                      value={expenseData?.reason}
                      disabled={true}
                      fullwidth={true}
                  />
              </Box>
              <Box sx={{width:"26.25rem", backgroundColor:"#F5F5F59E", borderRadius:"12px", padding:"1rem"}}>
                  <Box sx={{color:"#818181", fontSize:"1rem", lineHeight:"1.5rem"}}>Approvers</Box>
                  <Box>
                   {approvers.map((approver)=>(
                    <Box sx={{padding:"1.25rem", border:"1px solid #A2A1A833", marginTop:"0.625rem", borderRadius:"10px"}}>
                      <Box sx={{width:"13.0625rem", padding:"3px 5px", borderRadius:"10px", backgroundColor:"#D9D9D9", fontSize:"0.875rem", lineHeight:"1.5rem", color:"#0063F5"}}>
                        {approver?.fname+" "+approver?.lname}
                      </Box>
                    </Box>
                   ))}
                  </Box>
              </Box>
          </Box>
          {expenseData?.status==="pending"?
                <Box sx={{ width:"100%", display: "flex", gap: "1rem", justifyContent:"end"}}>
                    <BtnSecondary onClick={handleReject} sx={{ borderRadius: "8px", height: "3rem", width:"7.81rem"}}>
                        Reject
                    </BtnSecondary>
                    <BtnPrimary onClick={handleApprove} sx={{ borderRadius: "8px", height: "3rem", width:"7.81rem"}}>
                        Approve
                    </BtnPrimary>
                </Box>
           :null}
          <ActionDialog 
            type="approve" 
            open={approveDialogOpen} 
            onClose={handleCloseApproveDialog} 
            reviewApi={approveExpense}
            id={id} 
            text="Approve Expense?"
          />
          <ActionDialog 
            type="reject" 
            open={rejectDialogOpen} 
            onClose={handleCloseRejectDialog} 
            reviewApi={rejectExpense}
            id={id}
            text="Reject Expense?"
          />
        </MainBox>
        }
      </Box>
  )
}

export default ExpenseRequestDetails


