import React, { useState } from 'react';
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import CalenderIcon from "../assets/icons/calendar-dash.svg";
import DateExpand from "./DateExpand";
import ClearIcon from '@mui/icons-material/Clear';

const DatePicker = ({ value, styles, onDateSelect}) => {

  const [isDialogOpen,setIsDialogOpen]=useState(false);

  const onSelect = (date) =>{
    onDateSelect(date);
    setIsDialogOpen(false);
  }

  const handleClearDate = (event) => {
    event.stopPropagation();
    onDateSelect("");  
  }

  return (
    <Box>
      <TextField
        value={value || new Date().toLocaleDateString('en-GB')}
        onClick={()=>setIsDialogOpen(true)}
        variant="outlined"
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <img src={CalenderIcon} alt="Calendar" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClearDate} sx={{height:"1rem", width:"1rem"}}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ 
          "& .MuiInputBase-root":{
                ...styles,
                width:"11rem",  
                borderRadius:"12px",
                fontSize: '1rem',
                lineHeight:"1.5rem",
                color:"#16151C",
                fontWeight: '300',
                paddingInline:"1rem"
          },
          "& .MuiOutlinedInput-notchedOutline":{
                border:"0.5px solid #16151C33"
          }
        }}
      />
      <DateExpand open={isDialogOpen} onClose={()=>setIsDialogOpen(false)} onSelect={onSelect} />
    </Box>
  );
};

export default DatePicker;
