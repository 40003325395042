import React, { useEffect, useState } from "react";
import {Box, Popper} from "@mui/material";
import TopBar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import DropdownButton from "../../../../../../../component/DropdownButton";
import BtnSecondary from "../../../../../../../component/SecondaryButton";
import { PolicyTableHeadings} from '../../../../../../../styles/PolicyTableHeadings';
import { PolicyTableCell} from '../../../../../../../styles/PolicyTableCell';
import PaginationTable from "../../../../../../../component/PaginationTable";
import { getOrgShifts } from "../../../../../../../services/globalData";
import TableHeader from '../../../../../../../component/TableHeader';
import { useLocation } from "react-router";
import { getEmployeeDetails } from "../../../../../../../utils/getEmployeeNames";
import { calculateTime } from "../../../../../../../utils/calculateTime";
import { formatTimeString } from "../../../../../../../utils/formatTimeString";
import { isPointInFence } from "../../../../../../../utils/isPointInFence";
import { getGeofence } from "../../../../../../../services/geofence";
import Loader from "../../../../../../../component/Loader";
import { getDuration } from "../../../../../../../utils/getDuration";
import MapWithMarker from "../../../../../../../component/MapWithMarker";


const AllEmployeesAttendance = () => {
  
  const [loading,setLoading] = useState(false);
  const shiftOptions = getOrgShifts()?.map((shift) => ({
    value: shift?._id,
    name: shift?.name,
  }));
  const [selectedShift, setSelectedShift] = useState(shiftOptions[0]?.value);
  const [searchQuery, setSearchQuery] = useState("");
  const {state} = useLocation();
  const [filteredAttendanceData, setFilteredAttendanceData] = useState([]);
  const [fence,setFence] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [location,setLocation] = useState([]);

  console.log(fence);
  
  const handleMouseEnter = (event, data) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    console.log(data);
    setLocation({ lat: data?.arrivalLat, lng: data?.arrivalLong });
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  useEffect(()=>{
      fetchGeofences();
      transformArray();
  },[]);

  const transformArray = async() =>{
    setLoading(true);
    const transformedArray = state?.employeesAttendanceData?.flatMap(({ date, employees }) =>
      employees.map(({ employeeId, records }) => ({ date, employeeId, records }))
    ); 
    const employeeIds = transformedArray?.map(data => data?.employeeId);
    const combinedData = await getEmployeeDetails(transformedArray, employeeIds, "employeeId"); 
    setFilteredAttendanceData(combinedData.reverse());
    setLoading(false);
  }

  const fetchGeofences = async () => {
    const getFences = await getGeofence();
    const fences = getFences?.data[0]?.geofenceObj?.fences[0];
    setFence(fences);
  };

  console.log(filteredAttendanceData);

  const headings = [
    { label: "Date", style: {...PolicyTableHeadings, width:"4.25rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Employee Name", style: {...PolicyTableHeadings,  width:"10rem"} },
    { label: "Employee ID", style: {...PolicyTableHeadings, width:"10rem"} },
    { label: "Location", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Status", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Clock In", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Clock Out", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Working Hours", style: { ...PolicyTableHeadings,width:"7.5rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
  ];

  return (
    loading?(
     <Loader/>
    ):(
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Attendance"} 
          breadcrumbs={[
            {label:"Attendance"},
            {label:"Dashboard", href:"/app/attendance-dashboard"},
            {label:"All Employee Attendance"}
          ]}
        />
        <MainBox sx={{ padding: "2.5rem 1.25rem"}}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem", alignItems:"end" }}>
                <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                   Employee Attendance Details
                </Box>
                <Box sx={{display:"flex", gap:"1rem"}}>
                  <DropdownButton 
                    value={selectedShift} 
                    options={shiftOptions} 
                    onChange={(e)=>setSelectedShift(e.target.value)}
                  />                  
                  <BtnSecondary sx={{ width: '5.56rem', height: '2rem', borderRadius: '8px' }} to="/app/attendance-dashboard">
                      Back
                  </BtnSecondary>
                </Box>
            </Box>
             <TableHeader 
                data={filteredAttendanceData}
                sx={{height:"2.25rem"}}
                searchQuery={searchQuery}
                handleSearch={(value)=>setSearchQuery(value)}
             />
             <PaginationTable
                rows={filteredAttendanceData}
                headings={headings}
                loading={loading}
                link={true}
                path="/app/attendance-details"
                id="employeeId"
                noRecordMessage={"No Record Found"}
                renderCell={(row) =>{ 
                  const isRemote = !isPointInFence(fence, row?.records[0]?.arrivalLat, row?.records[0]?.arrivalLong);
                  return(
                  <>
                    <PolicyTableCell>{row?.date}</PolicyTableCell>
                    <PolicyTableCell>{row?.employee?.fname+" "+row?.employee?.lname}</PolicyTableCell>
                    <PolicyTableCell>{row?.employee?.aliasId}</PolicyTableCell>
                    <PolicyTableCell>
                      <Box 
                        onMouseEnter={isRemote ? (event) => handleMouseEnter(event, row?.records[0]) : undefined}
                        onMouseLeave={isRemote ? handleMouseLeave : undefined}
                        style={{
                          cursor: isRemote ? 'pointer' : 'default',
                          textDecoration: isRemote ? 'underline' : 'none',
                        }}
                      >
                           {isRemote ? "Remote" : "In Office"}                      
                      </Box>
                    </PolicyTableCell>
                    <PolicyTableCell>Present</PolicyTableCell>
                    <PolicyTableCell>{formatTimeString(row?.records[0]?.createdAt)}</PolicyTableCell>
                    <PolicyTableCell>{!isNaN(Number(row?.records[0]?.departureTime)) ? calculateTime(row?.records[0]?.departureTime) : "--"}</PolicyTableCell>
                    <PolicyTableCell>{getDuration(row.records?.[row?.records?.length - 1]?.totalHours)}</PolicyTableCell>
                  </>
                )}}
              />
              <Popper 
                 id="popper-id" 
                 open={open} 
                 anchorEl={anchorEl}  
                 placement="bottom-start" 
                 onMouseEnter={() => setOpen(true)}  
                 onMouseLeave={() => setOpen(false)} 
                 sx={{width:"14rem", height:"20.06rem", border: "1px solid #D5D4DF",borderRadius:"6px",boxShadow: "0px 4px 4px 0px #00000040",padding:"0.75rem", backgroundColor:"white"}}
              >
                    <MapWithMarker
                      sx={{width:"12.5rem", height:"15.625rem", border: "1px solid #5E81F4", borderRadius:"12px"}}
                      location={location}
                      defaultZoom={16}
                    />
                   <Box sx={{display:"flex", justifyContent:"center", marginTop:"0.75rem"}}>
                         <Box sx={{fontWeight:"500",color:"#0063F5",fontSize:"0.875rem", lineHeight:"1.375rem"}}>
                           Location
                         </Box>
                   </Box>
              </Popper>
        </MainBox>
     </Box>
    )
  );
};

export default AllEmployeesAttendance;