import { postCall, getCall} from "./api";

export const createGroup = async (input = {}) => {
  const request = {
    path: `/employee-group`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const getAllGroups = async () => {
  const request = {
    path: `/employee-group`,
  };
  return await getCall(request);
};
