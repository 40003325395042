import React, { useEffect, useState } from "react";
import {Box} from "@mui/material";
import Topbar from "../../../../../../component/TopBar";
import CurrentDateTime from "../../../../../../component/CurrentDateTime";
import { SearchBox } from "../../../../../../component/SearchBox";
import { getOrgEmployees } from "../../../../../../services/globalData";
import GoogleMapWithPolyline from '../../../../../../component/GoogleMapPolyline';
import { getLatestTripByEmployeeId } from "../../../../../../services/trips";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RefreshIcon from '@mui/icons-material/Refresh';
import BlueCircle from '../../../../../../assets/icons/blue-circle.svg';

const LiveTracking = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const employees = getOrgEmployees();
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [startLocation,setStartLocation] = useState([]);
  const [endLocation,setEndLocation] = useState([]);
  const [middleLocations,setMiddleLocations] = useState([]);
  const [trip, setTrip] = useState(null);
  const [message, setMessage] = useState("");

  console.log(employees);

  useEffect(()=>{
    setFilteredEmployees(
        employees?.filter((item) =>
          searchQuery === ""
            ? item
            : item?.fname?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
              item?.lname?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    ))
  },[searchQuery])

  const getOngoingTripByEmployeeId = async (empId) => {
    try {
      const res = await getLatestTripByEmployeeId(empId);
      const tripData = res?.data;
      console.log(tripData);
  
      if (!tripData || tripData?.isCompleted) {
        setMessage("No Ongoing Trip");
        setTrip(null);
      } else {
        setTrip(tripData);
        setStartLocation(tripData?.startLocation);
        setMiddleLocations(tripData?.middleLocations);
        setEndLocation(tripData?.endLocation);   
        setMessage("");
      }
    } catch (error) {
      console.error("Error fetching trip data:", error);
      setMessage("Error fetching trip data");
      setTrip(null);
    }
  };

  return (
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
      <Topbar MainHeading={"Field Staff Tracking"} breadcrumbs={[{label:"Live Location"}]}/>
      <Box sx={{ border: "1px solid #A2A1A833",borderRadius: "10px", width: "100%", height:"100vh"}}>
        <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center",padding:"1rem"}}>
            <Box sx={{color:"#0A0A0A",fontSize: '1rem',fontWeight: 600,lineHeight: '1.5rem'}}>
                Employees
            </Box>
            <CurrentDateTime/>
        </Box>
        <Box sx={{display:"flex", borderBlock:"1px solid #A2A1A833"}}>
           <Box sx={{width:"28%", padding:"1rem"}}>
              <SearchBox 
                    styles={{height:"2.25rem", width:"16em"}}
                    searchQuery={searchQuery}
                    handleSearch={(value)=>setSearchQuery(value)}
                />
            </Box>
            <Box sx={{width:"70%", padding:"1rem"}}>   
                    {trip ? (
                        <Box sx={{width:"100%",display:"flex", justifyContent:"space-between"}}>
                            <Box sx={{color:"#0A0A0A",fontSize: '1.125rem',fontWeight: 600,lineHeight: '1.5rem'}}>
                                Trip Id: {trip?.aliasId || "--"}
                            </Box>
                            <Box sx={{display:"flex", gap:"0.25rem",alignItems:"end"}}>
                                <RefreshIcon 
                                  sx={{height:"1.25rem",color:"#0081FF",marginBlock:"0.15rem",":hover":{cursor:"pointer"}}} 
                                  onClick={()=>getOngoingTripByEmployeeId(selectedEmployeeId)}
                                />
                                <Box sx={{fontSize: '0.875rem',lineHeight: '1.5rem'}}>
                                    <Box sx={{display:"flex",alignItems:"center", gap:"0.5rem"}}>
                                        <Box sx={{fontWeight:600}}>Trip Started At:</Box>
                                        <Box>{new Date(trip?.startTime).toLocaleString() || "--"}</Box>
                                        <LocationOnIcon sx={{height:"1.5rem",color:"#00e64d",stroke: "black",strokeWidth:1}}/>
                                    </Box>
                                    <Box sx={{display:"flex", alignItems:"center", gap:"0.5rem"}}>
                                        <Box sx={{fontWeight:600}}>Last Updated At:</Box>
                                        <Box>{new Date(trip?.updatedAt).toLocaleString() || "--"}</Box>
                                        <img src={BlueCircle} alt="last updated at" style={{height:"1rem"}}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box> 
                    ):null} 
            </Box> 
        </Box>
        <Box sx={{display:"flex"}}>
            <Box sx={{width:"40%", paddingInline:"1rem", backgroundColor:"white", borderRight:"1px solid #A2A1A833"}}>                 
                 <Box sx={{height: "calc(100vh - 12rem)", overflowY: "auto", paddingRight:"0.5rem"}}>
                 {
                    filteredEmployees?.map?.((employee)=>(
                        <Box 
                            sx={{
                            marginBlock:"0.5rem",
                            borderRadius:"10px", 
                            backgroundColor:"#f5f5f5", 
                            padding:"1rem 0.75rem",
                            border: employee?._id===selectedEmployeeId ?"0.5px solid #0081ff":"none",
                            boxShadow:employee?._id===selectedEmployeeId ?"0px 4px 4px 0px #00000040":"none",
                            '&:hover': {backgroundColor: '#EFEFEF',boxShadow:"0px 4px 4px 0px #00000040", cursor:"pointer"},
                            }}
                            onClick={()=>{
                                setSelectedEmployeeId(employee?._id)
                                getOngoingTripByEmployeeId(employee?._id)
                            }}
                        >
                            <Box sx={{color:"#111827"}}>
                                    <Box sx={{fontWeight:"500", fontSize:"1rem", lineHeight:"1.25rem"}}>
                                    {employee?.fname+" "+employee?.lname}
                                    </Box>
                                    <Box sx={{fontWeight:"500", fontSize:"0.75rem", lineHeight:"1.25rem"}}>
                                        {employee?.aliasId}
                                    </Box>
                                    <Box sx={{fontWeight:"300", fontSize:"0.75rem", lineHeight:"1.25rem"}}>
                                        {employee?.designation || "--"}
                                    </Box>
                                    
                            </Box>
                        </Box>
                    ))
                 }
                </Box>
            </Box>
            <Box sx={{width:"100%", height:"100%"}}>  
                {trip?(
                    <GoogleMapWithPolyline 
                        startLocation={startLocation}
                        endLocation={endLocation}
                        middleLocations={middleLocations}
                        sx={{height:"80vh"}}
                        endLocationIcon={BlueCircle}
                    />
                )
                : (    
                        <Box sx={{fontWeight:"600",fontSize:"1.25rem",lineHeight:"1.875rem",color:"#16151C", height:"80vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
                                {message ? message : "Select an Employee to track their live location"}
                        </Box>
                )}
            </Box>      
          </Box>
        </Box>
    </Box>
   )
};

export default LiveTracking