import { getCall, putCall, postCall } from "./api";

export const createPolicy = async (input = {}) => {
  const request = {
    path: `/policy`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const getSelfPolicy = async () => {
  const request = {
    path: `/policy/self`,
  };
  return await getCall(request);
};

export const updatePolicy = async (policyId, input = {}) => {
  const request = {
    path: `/policy/${policyId}`,
    bodyParams: input,
  };
  return await putCall(request);
};

  

  
  