import React from 'react';
import { Dialog,Box, TableCell} from '@mui/material';
import PaginationTable from '../../../../../../component/PaginationTable';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const CustomTableCell = {
    fontFamily:"Poppins",
    fontWeight: "300",
    fontSize: "1rem",
    color: "#000000",
    lineHeight: "1.5rem",
    padding: "1rem",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis", 
    overflow:"hidden"
};

const Headings = {
    fontFamily:"Poppins",
    fontWeight: "300",
    fontSize: "1rem",
    color: "#000000",
    lineHeight: "1.5rem",
    padding: "1.375rem 1rem",
    backgroundColor: "#F5F5F5",
    borderBottom: "none",
    textAlign:"center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }
  
function PendingExpenseDialog({open, onClose,data, pendingAmount}) {

 const headings = [
    { label: "Date", style: {...Headings, width:"6.43rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Employee", style: {...Headings, width:"6.43rem", maxWidth: "6.43rem"} },
    { label: "Expense Id", style: {...Headings, width:"6.43rem"} },
    { label: "Amount", style: {...Headings, width:"6.43rem"} },
    { label: "Status", style: { ...Headings, width:"6.43rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width:"53.6875rem",
          maxWidth: "none",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "2.25rem 1.5rem",
          display:"flex",
          flexDirection:"column",
          alignItems:"center",
        }
      }}
    >
        <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}> 
            <Box sx={{fontSize:"1.25rem",fontWeight:"500",lineHeight:"1.875rem", color:"#0081FF", textAlign:"left", marginBottom:"1.5rem"}}>
                Pending for Verification
            </Box>
            <CloseOutlinedIcon 
                fontSize='small' 
                onClick={()=>onClose()} 
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}/>
        </Box>
        
        <PaginationTable
            rows={data}
            headings={headings}
            noRecordMessage={"No Record Found"}
            paginationHide={true}
            renderCell={(row) => (
            <>
                <TableCell 
                align="center"
                sx={{...CustomTableCell, width:"6.43rem",  maxWidth:"6.43rem"}}
                >
                    {row.date}
                </TableCell>
                <TableCell 
                align="center"
                sx={{...CustomTableCell, width:"9rem", maxWidth: "9rem"}}
                >
                    {row.employeeName}
                </TableCell>
                <TableCell 
                align="center"
                sx={{...CustomTableCell, width:"6.43rem", maxWidth: "6.43rem"}} 
                >
                    {row.expenseId}
                </TableCell>
                <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>{"₹ "+row.amount}</TableCell>
                <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>
                        <Box sx={{
                                borderRadius:"4px", 
                                padding:"4px 8px",
                                textAlign:"center",
                                backgroundColor: row.status === "pending" ? "#FFBE64" : (row.status === "rejected" ? "#E493931A" : "#0CBD7D"),
                                color: row.status === "pending" ? "#212423" : (row.status === "rejected" ? "#E52E2E" : "#FFFFFF")
                                }}>
                            {row.status}
                        </Box>
                </TableCell>
            </>
            )}
        />
        <Box sx={{display:"flex", alignItems:"center", width:"100%", height:"3.125rem", backgroundColor:"#EEEEEE52", padding:"1rem"}}>
               <Box sx={{width:"30.8rem", fontSize:"1rem", lineHeight:"1.875rem", fontWeight:"500", color:"#16151C"}}>
                   Total Amount under Review
               </Box>
               <Box sx={{width:"8.43rem",fontSize:"1rem", lineHeight:"1.875rem", fontWeight:"700", color:"#EB5757", textAlign:"center"}}>
                   {"₹ "+pendingAmount}
               </Box>
        </Box>
    </Dialog>
  );
}

export default PendingExpenseDialog;

