import React,{useState} from 'react'
import {TextField, Autocomplete } from '@mui/material'
import { getOrgDepartments } from '../services/globalData'

const Department = ({department, setDepartment, sx}) => {
  
  const currentDept = getOrgDepartments()?.filter((curr)=> curr._id === department)?.map((department)=> ({
    id:department._id,
    label:department.name
  }));

  const [dept,setDept] = useState(currentDept||{id:"",label:""})

  const departmentOptions = getOrgDepartments()?.map((department)=> ({
     id:department._id,
     label:department.name
  }))

  return (
    <Autocomplete
      options={departmentOptions}
      renderInput={(params)=><TextField {...params} label="Department"/>}
      value={dept}
      size="small"
      onChange={(e,newValue)=> {
        setDept(newValue)
        setDepartment(newValue?.id)
      }}
      sx={{ 
          "& .MuiInputBase-root":{
                width:"12rem",
                height:"3.5rem",
                borderRadius:"12px",
                fontSize: '1rem',
                lineHeight:"1.5rem",
                color:"#16151C",
                fontWeight: '300',
                ...sx
          },
          "& .MuiOutlinedInput-notchedOutline":{
                border:"0.5px solid #16151C33"
          }
        }}
    />
  )
}

export default Department
