import React,{useState, useEffect} from 'react';
import { Box, CircularProgress} from '@mui/material';
import ActiveBar from "../../../../../../component/PolicyNavbar";
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import TypeBtn from '../../../../../../component/AddRemoveButton';
import FormField from '../../../../../../component/Formfield';
import DateField from "../../../../../../component/DateField";
import {getAllLeaveType,createLeavePolicy} from "../../../../../../services/leave";
import {useLocation, useNavigate} from "react-router-dom";
import ErrorMsg from '../../../../../../component/ErrorMsg';

const LeavePolicyDetails = () => {
  const [apiCall,setApiCall]=useState(false);
  const navigate = useNavigate();
  const {state} = useLocation();
  const [policyForm,setPolicyForm] = useState({
    code:"",
    name:"",
    description:"",
    startDate:null,
    endDate:null,
  })
  const [startDateDialogOpen, setStartDateDialogOpen] = useState(false);
  const [endDateDialogOpen, setEndDateDialogOpen] = useState(false);
  const [leaveQuantities,setLeaveQuantities] = useState([]);
  const [leaveOptions, setLeaveOptions] = useState([]);
  const [validationErrors, setValidationErrors] = useState({code:"",
                                                            name:"",
                                                            description:"",
                                                          });
  const [error,setError]=useState("");

  useEffect(() => {   
    fetchLeaveTypeOptions();
  }, [state]); 

  const fetchLeaveTypeOptions = async () => {
    try {
      const res = await getAllLeaveType();
      console.log(res); 
      const mappedOptions = res.data.map(option => ({ value: option._id, name: option.name }));
      setLeaveOptions(mappedOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

   const handleAddLeaveQuantityClick = () =>{
      setLeaveQuantities([...leaveQuantities,{leaveType:"",quantity:""}])
    }

    const handleRemoveLeaveQuantityClick = (index) => {
      let updatedLeaveQuantities = [...leaveQuantities];
      updatedLeaveQuantities.splice(index, 1);
      setLeaveQuantities(updatedLeaveQuantities);
    }; 

    const handleLeaveChange = (index, fieldName, value) => {
      const updatedLeaveQuantities = [...leaveQuantities];
      updatedLeaveQuantities[index][fieldName] = value;
      setLeaveQuantities(updatedLeaveQuantities);
    };

    const PolicyFormData = {
      ...policyForm,
      leaveQuantities,
      startDate: (new Date(policyForm.startDate)).getTime(),
      endDate: (new Date(policyForm.endDate)).getTime()
    };

    const handleNextClick = async() => {

      const errors ={};
      if (!PolicyFormData.code) {
        errors.code="Code is required"
      }
      if (!PolicyFormData.name) {
          errors.name="Name is required"
      }
      if (!PolicyFormData.startDate) {
         errors.startDate="Start Date is required"
      }
      if (!PolicyFormData.endDate) {
         errors.endDate="End Date is required"
      }
      if (!PolicyFormData.description) {
        errors.description="Description is required"
     }
      
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      setApiCall(true);
      try {
          const res = await createLeavePolicy(PolicyFormData);
          console.log(res);
          if(res.success){
            navigate("/app/leave-policy/assign-policy",{state:{policyId: res.data._id}});
          } else {
              console.error('Policy creation failed:', res.error);
              setError(res.message);
          }
      } catch (error) {
          console.error('Error saving Policy:', error);
      }
      setApiCall(false);
  };

  const handleChange = (field, value) => {
    const updatedValue = field === "code" ? value?.toUpperCase() : value;
    setPolicyForm(prevState => ({...prevState, [field]: updatedValue}));
    setValidationErrors(prevState => ({...prevState, [field]: ""}));
    setError("");
  };
  
  return (
    <Box sx={{width:"100%"}}>
        <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
              <ActiveBar active={"Policy Details"} value="18"/>
              <BtnSecondary to={"/app/leave-policy"} sx={{width:"5.56rem", height:"2.25rem", borderRadius:"10px"}}>
                  Back
              </BtnSecondary>
        </Box>
        <Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "1rem", justifyContent: "space-between" }}>
                    <FormField
                        label="Policy Code"
                        type="text"
                        value={policyForm?.code}
                        validation={validationErrors?.code}
                        onChange={(e) => handleChange('code', e.target.value)}
                    />
                    <FormField
                        label="Policy Name"
                        type="text"
                        value={policyForm?.name}
                        validation={validationErrors?.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                    <DateField
                      label="Start Date"
                      value={policyForm.startDate}
                      isDialogOpen={startDateDialogOpen}
                      openDialog={() => setStartDateDialogOpen(true)}
                      closeDialog={() => setStartDateDialogOpen(false)}
                      validation={validationErrors.startDate}
                      onSelect={(date) => {
                        handleChange('startDate', date)
                        setStartDateDialogOpen(false)
                        }}
                    />
                    <DateField
                      label="End Date"
                      value={policyForm.endDate}
                      isDialogOpen={endDateDialogOpen}
                      validation={validationErrors.endDate}
                      openDialog={() => setEndDateDialogOpen(true)}
                      closeDialog={() => setEndDateDialogOpen(false)}
                      onSelect={(date) => {
                        handleChange('endDate', date)
                        setEndDateDialogOpen(false)
                      }}
                    />
                    <FormField
                        label="Description"
                        type="text"
                        value={policyForm?.description}
                        validation={validationErrors.description}
                        fullwidth
                        row={4}
                        onChange={(e) => handleChange('description', e.target.value)}
                    />
          </Box>
          <TypeBtn type="add" onClick={handleAddLeaveQuantityClick}>
                Add Leave Type
          </TypeBtn>
          {
            leaveQuantities?.map((item,index)=>(
              <Box key={index} sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                     <FormField
                         label="Leave Type"
                         type="select"
                         options={leaveOptions}
                         value={item?.leaveType}
                         onChange={(e) => handleLeaveChange(index, 'leaveType', e.target.value)}
                     />
                     <FormField
                         label="Leave Quantity"
                         type="text"
                         value={item?.quantity}
                         onChange={(e) => handleLeaveChange(index, 'quantity', e.target.value)}
                     />
                     <Box sx={{width:"100%", display:"flex", justifyContent:"end"}}>
                         <TypeBtn type="remove" onClick={()=>handleRemoveLeaveQuantityClick(index)}>
                            Remove 
                         </TypeBtn>
                     </Box>
              </Box>
            ))
          }
        </Box>
        <ErrorMsg>{error}</ErrorMsg>
        <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
              <BtnPrimary 
                onClick={handleNextClick} 
                sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                disabled={apiCall}
              >
                  {apiCall?<CircularProgress/>:"Next"}
              </BtnPrimary> 
        </Box>
    </Box>
    )
}

export default LeavePolicyDetails;