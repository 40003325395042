import React,{useState, useEffect} from 'react';
import {Avatar, Box, CircularProgress} from '@mui/material';
import BtnPrimary from "../../../../../../component/PrimaryButton";
import BtnSecondary from "../../../../../../component/SecondaryButton";
import FormField from "../../../../../../component/Formfield";
import { createPolicyCateogary } from "../../../../../../services/expense";
import { getPolicyCategoryById, updatePolicyCategory} from "../../../../../../services/policyCategory";
import { getLegalUnitList} from "../../../../../../services/globalData";
import { useNavigate,useLocation } from "react-router-dom";
import DeleteWarning from '../../../../../../component/DeleteWarning';
import ErrorMsg from '../../../../../../component/ErrorMsg';
import ProgressBar from '../../../../../../component/ProgressBar';
import DocsIcon from '../../../../../../assets/icons/docs.svg';
import { currentUserDetails } from '../../../../../../utils/currentUserDetails';
import AttributeForm from './AttributeForm';
import SuccessDialog from "../../../../../../component/SuccessDialog";

const ExpenseTypeForm = () => {
  const [loading,setLoading]=useState(false);
  const [apiCall,setApiCall]=useState(false);
  const [error,setError]=useState("");
  const navigate = useNavigate();
  const {state} = useLocation();
  const [mode, setMode] = useState('create'); 
  const [typeId, setTypeId] = useState(null);
  const [isDeleteDialogOpen,setIsDeleteDialogOpen] = useState(false);
  const [isSaveDialogOpen,setIsSaveDialogOpen] = useState(false);
  const [isAttributeDialogOpen,setIsAttributeDialogOpen] = useState(false);
  const [attributes,setAttributes] = useState([]);
  const user = currentUserDetails();
  const createdBy = user.fname+" "+user.lname;
  const [dialogMessage,setDialogMessage] = useState("");
  const [expenseForm,setExpenseForm] = useState({
    code:"",
    name:"",
    description:""
  })

  const [validationErrors, setValidationErrors] = useState({
    code:"",
    name:"",
  });

  useEffect(() => {
    if (state && state.typeId) {
        setMode('view');
        setTypeId(state.typeId);
        fetchTypeData(state.typeId); 
    } 
  }, [state]);

  const fetchTypeData = async (typeId) => {
    setLoading(true);
    try {
        const res = await getPolicyCategoryById(typeId); 
        const data = res.data;
        console.log(data);
        if(res.success){
            setExpenseForm(prevState => ({
              ...prevState,
              code: data?.code,
              name: data?.name,
              categoryType: data?.categoryType,
              description: data?.description,
          })); 
        }
    } catch (error) {
        console.error('Error fetching expense category data:', error);
    }
    setLoading(false);
  };

  const handleDelete = () =>{
    setIsDeleteDialogOpen(true);
  }

  const handleChange = (field, value) => {
    const updatedValue = field === "code" ? value?.toUpperCase() : value;
    setExpenseForm(prevState => ({...prevState,[field]: updatedValue}));
    setValidationErrors(prevState => ({...prevState,[field]: "" }))
  };

    const modeSwitch = () =>{
      setMode(mode==="edit"?"view":"edit")
    }

  const handleSaveClick = async() => {
      const errors ={};
      if (!expenseForm.code) {
        errors.code="Code is required";
      }
      if (!expenseForm.name) {
        errors.name="Name is required";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      try {
        setApiCall(true);
        if (mode === 'create') {
            expenseForm.type = "ANY";
            expenseForm.attributes = attributes;
            const legalUnit = getLegalUnitList();
            expenseForm.legalUnit = legalUnit[0]._id;
            const res = await createPolicyCateogary(expenseForm);
            if (res.success) {
                setDialogMessage(`${expenseForm.name} category successfully created!`);
                setExpenseForm({
                  code:"",
                  name:"",
                  description:""
                })
                setIsSaveDialogOpen(true);
            } else {
                console.error('Expense Category creation failed:', res.error);
                if(res.message.includes("policy cateogary with code already exists"))
                  setError("Expense type with same code exists");
            }
        } else if (mode === 'edit') {
            const res = await updatePolicyCategory(typeId,expenseForm);
            console.log(res); 
            if (res.success) {
                setDialogMessage(`${expenseForm.name} category successfully updated!`);
                setExpenseForm({
                  code:"",
                  name:"",
                  description:""
                })
                setMode("create");
                setIsSaveDialogOpen(true);
            } else {
                console.error('Expense Category updation failed:', res.error);
                if(res.message.includes("policy cateogary with code already exists"))
                  setError("Expense type with same code exists");
            }
        }
      } catch (error) {
          console.error('Error in expense type:', error);
      }
      setApiCall(false);
   };

  return (
    loading?(
      <Box sx={{width:"100%",display:"flex", justifyContent:"center"}}>
        <CircularProgress/>
      </Box>
    ):(
    <Box sx={{ width: "100%" }}>
      <Box sx={{display:"flex", justifyContent:"space-between"}}>
          <Box>
              <Box sx={{display:"flex"}}>
                <Avatar src={DocsIcon} style={{ width: '1.5rem', height: '1.5rem' }}/>
                <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                    Expense Categories
                </Box>
              </Box>
              <ProgressBar/>
          </Box> 
          <BtnSecondary 
                to="/app/expense-type"
                sx={{width:"5.56rem", height:"2.25rem", borderRadius:"10px"}}
            >
                   Back
          </BtnSecondary>
      </Box>
     <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "1rem", justifyContent: "space-between" }}>
                  <FormField
                      label="Category Code"
                      type="text"
                      value={expenseForm.code}
                      validation={validationErrors.code}
                      onChange={(e) => handleChange('code', e.target.value)}
                      disabled={mode === 'view'}
                  />
                  <FormField
                      label="Category Name"
                      type="text"
                      value={expenseForm.name}
                      validation={validationErrors.name}
                      onChange={(e) => handleChange('name', e.target.value)}
                      disabled={mode === 'view'}
                  />
                  <FormField
                      label="Created By"
                      type="text"
                      value={createdBy}
                      disabled={true}
                  />
                  <FormField
                    label="Description"
                    type="text"
                    value={expenseForm.description}
                    fullwidth
                    row={4}
                    disabled={mode === 'view'}
                    onChange={(e) => handleChange('description', e.target.value)}
                  />
                  {/* <Box sx={{fontSize: '1rem',lineHeight: '1.5rem',color: '#818181',marginBottom: '4px'}}>
                    Category Attributes
                  </Box>
                  <MainBox sx={{padding:"2rem"}}>
                        <Box sx={{width:"100%", display:"flex", gap:"1.25rem"}}>
                          {attributes.map(item => (
                            <Box sx={{borderRadius:"10px", padding:"0.5rem 1rem",
                                      backgroundColor:"#D9D9D9", color:"#0081FF", fontWeight:"300", 
                                      fontSize:"0.875rem", lineHeight:"1.5rem"
                                    }}>
                              {item.name}
                            </Box>
                          ))}
                        </Box>
                        <Box sx={{width:"100%", display:"flex", justifyContent:"end"}}>
                          <TypeBtn type="add" onClick={()=>setIsAttributeDialogOpen(true)}>
                              Add Attributes
                          </TypeBtn>
                        </Box>
                  </MainBox> */}
      </Box>
      <ErrorMsg>{error}</ErrorMsg>
      <Box sx={{ display: "flex", justifyContent: "end", gap: "1rem", marginBlock: "1.875rem" }}>
           {mode==="view"?
                <BtnSecondary onClick={modeSwitch} sx={{borderRadius:"10px",width:"5.56rem",height:"2.25rem"}}>
                   Edit
                </BtnSecondary> 
            :(
              <Box sx={{display:"flex", gap:"0.5rem"}}>
                  <BtnPrimary 
                      onClick={handleSaveClick} 
                      sx={{ borderRadius: "8px", width: "11.875rem", height: "3.125rem" }}
                      disabled={apiCall}
                  >
                    {apiCall?<CircularProgress/>:"Save Category"}
                  </BtnPrimary>
                  {mode==="edit"?(
                     <BtnSecondary 
                     onClick={handleDelete} 
                     sx={{borderRadius: "8px", width: "11.875rem", height: "3.125rem" }}
                      >
                        Delete
                      </BtnSecondary>
                  ):null}
              </Box>
        )}
      </Box>
      <SuccessDialog 
        open={isSaveDialogOpen} 
        onClose={()=>setIsSaveDialogOpen(false)} 
        addNewLink={"/app/expense-type/form"} 
        addNewText={"Create new category"}
        viewAllLink={"/app/expense-type"}
        viewAllText={"View all categories"}
        message={dialogMessage}
      />
      <DeleteWarning open={isDeleteDialogOpen} onClose={()=>setIsDeleteDialogOpen(false)} redirectLink={"/app/expense-type"}/>
      <AttributeForm 
        open={isAttributeDialogOpen} 
        onClose={(attribute)=>{
         setAttributes((prev)=>[...prev,attribute])
         setIsAttributeDialogOpen(false)
        }}/>
    </Box>
   )
  );
};

export default ExpenseTypeForm;
