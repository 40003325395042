import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/home";
import Main from "./routes/main";
import ForgetPassword from "./routes/forgetPassword";
import Signup from "./routes/signup";
import Login from "./routes/login";
import RecoverAccount from "./routes/recoverAccount";
import ErrorPage from "./routes/ErrorPage";

// Employee
import LeaveRequest from "./app/main/controller/employee-main/leave/index";
import Expense from "./app/main/controller/employee-main/expenses/index";
import AddNewExpense from "./app/main/controller/employee-main/expenses/AddNewExpense";
import MyExpenses from "./app/main/controller/employee-main/expenses/MyExpenses";
import EmployeeAttendanceDashboard from "./app/main/controller/employee-main/attendance/index";

// Admin
import Employees from './app/main/controller/admin-main/employee/AllEmployees/index';
import AllEmployee from "./app/main/controller/admin-main/employee/AllEmployees/EmployeesTable";
import EmployeeDetails from "./app/main/controller/admin-main/employee/AllEmployees/employee-details/index";
import PersonalDetails from "./app/main/controller/admin-main/employee/AllEmployees/employee-details/PersonalDetails";
import SelfIdentification from "./app/main/controller/admin-main/employee/AllEmployees/employee-details/SelfIdentification";
import EmployeeOnboarding from "./app/main/controller/admin-main/employee/Onboarding/index";
import OnboardingInvite from "./app/main/controller/admin-main/employee/Onboarding/OnboardingInvite";
import Dashboard from "./app/main/controller/admin-main/dashboard/index";
import Trips from "./app/main/controller/admin-main/expenses/trips/tripspolicy/index";
import AttendanceDashboard from "./app/main/controller/admin-main/attendance/dashboard/index";
import EmployeeAttendance from "./app/main/controller/admin-main/attendance/dashboard/employees-attendance/EmployeeAttendance";
import MyAttendance from "./app/main/controller/admin-main/attendance/my-attendance/index";
import LeaveDashboard from "./app/main/controller/admin-main/leave/dashboard/index";
import LeaveRequests from "./app/main/controller/admin-main/leave/dashboard/requests/index";
import RequestDetails from "./app/main/controller/admin-main/leave/dashboard/requests/RequestDetails";
import LeavePolicy from "./app/main/controller/admin-main/leave/leavepolicy/index";
import LeavePolicyListing from "./app/main/controller/admin-main/leave/leavepolicy/PolicyListing";
import LeavePolicyDetails from "./app/main/controller/admin-main/leave/leavepolicy/PolicyDetails";
import LeavePolicyAssignPolicy from "./app/main/controller/admin-main/leave/leavepolicy/assign-policy/index";
import LeavePolicyRules from "./app/main/controller/admin-main/leave/leavepolicy/PolicyRules";
import LeaveType from "./app/main/controller/admin-main/leave/leavetype/index";
import LeaveTypeListing from "./app/main/controller/admin-main/leave/leavetype/LeaveTypeListing";
import LeaveTypeForm from "./app/main/controller/admin-main/leave/leavetype/LeaveTypeForm";
import Settings from "./app/main/controller/admin-main/settings/index";
import Policy from "./app/main/controller/admin-main/expenses/policy";
import Timesheet from "./app/main/controller/admin-main/timesheet/index";
import TimeSheetGeneralSettings from "./app/main/controller/admin-main/timesheet/general-settings/index";
import Timelog from "./app/main/controller/admin-main/timesheet/general-settings/Timelog";
import EmployeeRate from "./app/main/controller/admin-main/timesheet/general-settings/EmployeeRate";
import ProjectConfiguration from "./app/main/controller/admin-main/timesheet/general-settings/ProjectConfiguration";
import Client from "./app/main/controller/admin-main/timesheet/general-settings/Client";

import ExpensePolicy from "./app/main/controller/admin-main/expenses/policy/index";
import ExpensePolicyListing from "./app/main/controller/admin-main/expenses/policy/ExpensePolicyListing";
import NewExpensePolicy from "./app/main/controller/admin-main/expenses/policy/new/index";
import ExpensePolicyDetails from "./app/main/controller/admin-main/expenses/policy/new/PolicyDetails";
import ExpensePolicyAddCategory from "./app/main/controller/admin-main/expenses/policy/new/AddCategory";
import ExpensePolicyAssignPolicy from "./app/main/controller/admin-main/expenses/policy/new/assign-policy/index";
import ExpensePolicyRules from "./app/main/controller/admin-main/expenses/policy/new/PolicyRules";
import ExpensePolicyEdit from "./app/main/controller/admin-main/expenses/policy/ExpensePolicyEdit";
import ExpenseDashboard from "./app/main/controller/admin-main/expenses/dashboard/index";
import ExpenseType from "./app/main/controller/admin-main/expenses/policyCategory/index";
import ExpenseTypeListing from "./app/main/controller/admin-main/expenses/policyCategory/ExpenseTypeListing";
import ExpenseTypeForm from "./app/main/controller/admin-main/expenses/policyCategory/ExpenseTypeForm";
import TripsPolicyListing from "./app/main/controller/admin-main/expenses/trips/tripspolicy/TripsPolicyListing";
import NewTripPolicy from "./app/main/controller/admin-main/expenses/trips/tripspolicy/newtrippolicy/index";
import TripsPolicyDetails from "./app/main/controller/admin-main/expenses/trips/tripspolicy/newtrippolicy/PolicyDetails";
import AddVehicleType from "./app/main/controller/admin-main/expenses/trips/tripspolicy/newtrippolicy/AddVehicleType";
import TripsPolicyAssignPolicy from "./app/main/controller/admin-main/expenses/trips/tripspolicy/newtrippolicy/assign-policy/index";
import PolicyRules from "./app/main/controller/admin-main/expenses/trips/tripspolicy/newtrippolicy/PolicyRules";
import TripsPolicyEdit from "./app/main/controller/admin-main/expenses/trips/tripspolicy/TripsPolicyEdit";
import VehicleType from "./app/main/controller/admin-main/expenses/trips/vehicletype/index";
import VehicleListing from "./app/main/controller/admin-main/expenses/trips/vehicletype/VehicleListing";
import VehicleForm from "./app/main/controller/admin-main/expenses/trips/vehicletype/VehicleForm";
import TripDetails from "./app/main/controller/admin-main/expenses/trips/tripsdashboard/TripDetails";
import Machines from "./app/main/controller/admin-main/asset-tracker/machine/index";
import MachineListing from "./app/main/controller/admin-main/asset-tracker/machine/MachineListing";
import MachineForm from "./app/main/controller/admin-main/asset-tracker/machine/MachineForm";
import Software from "./app/main/controller/admin-main/asset-tracker/software/index";
import SoftwareListing from "./app/main/controller/admin-main/asset-tracker/software/SoftwareListing";
import SoftwareForm from "./app/main/controller/admin-main/asset-tracker/software/SoftwareForm";
import EditDetails from "./app/main/controller/admin-main/employee/AllEmployees/employee-details/EditDetails";

import SalaryComponentListing from "./app/main/controller/admin-main/payroll/salary-component/listing/index";
import Deductions from "./app/main/controller/admin-main/payroll/salary-component/listing/Deductions";
import Earnings from "./app/main/controller/admin-main/payroll/salary-component/listing/Earnings";
import Reimbursements from "./app/main/controller/admin-main/payroll/salary-component/listing/Reimbursements";
import NewSalaryComponent from "./app/main/controller/admin-main/payroll/salary-component/new/index";
import Type from "./app/main/controller/admin-main/payroll/salary-component/new/Type";
import Form from "./app/main/controller/admin-main/payroll/salary-component/new/Form";
import StatutoryComponent from "./app/main/controller/admin-main/payroll/statutory-component/index";
import StatutoryComponentForm from "./app/main/controller/admin-main/payroll/statutory-component/Form";
import AllEmployeesAttendance from "./app/main/controller/admin-main/attendance/dashboard/employees-attendance/AllEmployeesAttendance";
import Requests from "./app/main/controller/admin-main/attendance/dashboard/employees-attendance/requests/index";
import RequestsListing from "./app/main/controller/admin-main/attendance/dashboard/employees-attendance/requests/RequestsListing";
import RequestsDetails from "./app/main/controller/admin-main/attendance/dashboard/employees-attendance/requests/RequestsDetails";
import Geofence from "./app/main/controller/admin-main/settings/geofence";
import AttendancePolicy from "./app/main/controller/admin-main/attendance/policy/index";
import AttendancePolicyListing from "./app/main/controller/admin-main/attendance/policy/AttendancePolicyListing";
import NewAttendancePolicy from "./app/main/controller/admin-main/attendance/policy/new";
import AttendancePolicyDetails from "./app/main/controller/admin-main/attendance/policy/new/PolicyDetails";
import AttendancePolicyAddCategory from "./app/main/controller/admin-main/attendance/policy/new/add-category/index";
import AttendancePolicyRules from "./app/main/controller/admin-main/attendance/policy/new/PolicyRules";
import AttendancePolicyAssignPolicy from "./app/main/controller/admin-main/attendance/policy/new/assign-policy";
import LeavePolicyEdit from "./app/main/controller/admin-main/leave/leavepolicy/LeavePolicyEdit";
import ShiftListing from "./app/main/controller/admin-main/attendance/shift/creation/ShiftListing";
import ShiftCreation from "./app/main/controller/admin-main/attendance/shift/creation";
import NewShift from "./app/main/controller/admin-main/attendance/shift/creation/new";
import ShiftDetails from "./app/main/controller/admin-main/attendance/shift/creation/new/ShiftDetails";
import AssignShift from "./app/main/controller/admin-main/attendance/shift/creation/new/assign-shift";
import ShiftRules from "./app/main/controller/admin-main/attendance/shift/creation/new/ShiftRules";
import ShiftRotation from "./app/main/controller/admin-main/attendance/shift/rotation";
import ShiftRotationListing from "./app/main/controller/admin-main/attendance/shift/rotation/ShiftRotationListing";
import NewShiftRotation from "./app/main/controller/admin-main/attendance/shift/rotation/new";
import RotationDetails from "./app/main/controller/admin-main/attendance/shift/rotation/new/RotationDetails";
import RotationFrequency from "./app/main/controller/admin-main/attendance/shift/rotation/new/RotationFrequency";
import EditShift from "./app/main/controller/admin-main/attendance/shift/creation/EditShift";
import AttendancePolicyEdit from "./app/main/controller/admin-main/attendance/policy/AttendancePolicyEdit";
import PayrollDashboard from "./app/main/controller/admin-main/payroll-new/dashboard";
import PayGroup from "./app/main/controller/admin-main/payroll-new/dashboard/PayGroup";
import PayGroupDetail from "./app/main/controller/admin-main/payroll-new/dashboard/PayGroupDetail";
import Approvals from "./app/main/controller/admin-main/payroll-new/dashboard/Approvals";
import SalaryTemplate from "./app/main/controller/admin-main/payroll-new/SalaryTemplate";
import AllEmployeePayroll from "./app/main/controller/admin-main/payroll-new/AllEmployeePayroll";
import ExpenseRequests from "./app/main/controller/admin-main/expenses/dashboard/ExpenseRequests";
import ExpenseRequestDetails from "./app/main/controller/admin-main/expenses/dashboard/ExpenseRequestDetails";
import LiveTracking from "./app/main/controller/admin-main/expenses/liveTracking/index";


const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage/>,
  },
  {
    path: "/app",
    element: <Main />,
    children: [
      // admin

      {
        index: true,
        element: <Dashboard />,
      },
      {
        path:"live-tracking",
        element: <LiveTracking/>
      },
      //Trips
      {
        path:"trips",
        element:<Trips/>,
        children:[
          {
             path:"listing",
             element:<TripsPolicyListing/>
          },
          {
            path:"edit",
            element:<TripsPolicyEdit/>
          },
          {
            path:"new",
            element:<NewTripPolicy/>,
            children:[
              {
                path:"setting",
                element:<TripsPolicyDetails/>
              },
              {
                path:"add-vehicle-type",
                element:<AddVehicleType/>
              },
              {
                path:"assign-policy",
                element:<TripsPolicyAssignPolicy/>
              },
              {
                path:"rules",
                element:<PolicyRules/>
              }
            ]
          },
        ]
      },
      {
        path:"vehicle-type",
        element:<VehicleType/>,
        children:[
          {
            index:true,
            element:<VehicleListing/>
          },
          {
            path:"form",
            element:<VehicleForm/>
          }
        ]
      },
      {
        path:"trip-details",
        element:<TripDetails/>
      },
      {
        path:"expense-dashboard",
        element:<ExpenseDashboard/>
      },
      {
        path:"expense-requests",
        element:<ExpenseRequests/>
      },
      {
        path:"expense-requests-details/:id",
        element:<ExpenseRequestDetails/>
      },
      {
        path:"expense-type",
        element:<ExpenseType/>,
        children:[
          {
            index:true,
            element:<ExpenseTypeListing/>
          },
          {
            path:"form",
            element:<ExpenseTypeForm/>
          }
        ]
      },
      {
        path:"expense-policy",
        element:<ExpensePolicy/>,
        children:[
          {
             path:"listing",
             element:<ExpensePolicyListing/>
          },
          {
            path:"edit",
            element:<ExpensePolicyEdit/>
          },
          {
            path:"new",
            element:<NewExpensePolicy/>,
            children:[
              {
                path:"setting",
                element:<ExpensePolicyDetails/>
              },
              {
                path:"add-category",
                element:<ExpensePolicyAddCategory/>
              },
              {
                path:"assign-policy",
                element:<ExpensePolicyAssignPolicy/>
              },
              {
                path:"rules",
                element:<ExpensePolicyRules/>
              }
            ]
          },
        ]
      },
      {
        path: "policy",
        element: <Policy />,
      },
      {
        path: "attendance-dashboard",
        element: <AttendanceDashboard />,
      },
      {
        path: "attendance-dashboard",
        element: <AttendanceDashboard />,
      },
      {
        path: "attendance-details/all-employees",
        element: <AllEmployeesAttendance/>,
      },
      {
        path: "attendance-details/requests",
        element: <Requests/>,
        children:[
          {
            index:true,
            element: <RequestsListing/>,
          },
          {
            path: "details",
            element: <RequestsDetails/>,
          }
        ]
      },
      {
        path: "attendance-details/:employeeId",
        element: <EmployeeAttendance/>,
      },
      {
        path: "attendance-details/my-attendance",
        element: <MyAttendance/>,
      },
      {
        path:"attendance-policy",
        element:<AttendancePolicy/>,
        children:[
          {
             path:"listing",
             element:<AttendancePolicyListing/>
          },
          {
            path:"edit",
            element:<AttendancePolicyEdit/>
          },
          {
            path:"new",
            element:<NewAttendancePolicy/>,
            children:[
              {
                path:"setting",
                element:<AttendancePolicyDetails/>
              },
              {
                path:"add-category",
                element:<AttendancePolicyAddCategory/>
              },
              {
                path:"assign-policy",
                element:<AttendancePolicyAssignPolicy/>
              },
              {
                path:"rules",
                element:<AttendancePolicyRules/>
              }
            ]
          },
        ]
      },
       //shift
       {
        path:"shift",
        element:<ShiftCreation/>,
        children:[
          {
             path:"listing",
             element:<ShiftListing/>
          },
          {
            path:"edit",
            element:<EditShift/>
          },
          {
            path:"new",
            element:<NewShift/>,
            children:[
              {
                path:"details",
                element:<ShiftDetails/>
              },
              {
                path:"assign-shift",
                element:<AssignShift/>
              },
              {
                path:"rules",
                element:<ShiftRules/>
              }
            ]
          },
        ]
      },
      {
        path:"shift-rotation",
        element:<ShiftRotation/>,
        children:[
          {
             path:"listing",
             element:<ShiftRotationListing/>
          },
          // {
          //   path:"edit",
          //   element:<AttendancePolicyEdit/>
          // },
          {
            path:"new",
            element:<NewShiftRotation/>,
            children:[
              {
                path:"rotation-details",
                element:<RotationDetails/>
              },
              {
                path:"rotation-frequency",
                element:<RotationFrequency/>
              },
            ]
          },
        ]
      },
      //People
      {
        path: "employees",
        element: <Employees/>,
        children:[
          {
            index:true,
            element: <AllEmployee />,
          },
          {
            path: "onboarding",
            element: <EmployeeOnboarding/>,
          },
          {
            path: "onboarding-invite",
            element: <OnboardingInvite/>,
          },
        ]
      },
      {
          path:"employee-details/:employeeId",
          element:<EmployeeDetails/>,
          children:[
            {
              index: true,
              // path:"personal",
              element:<PersonalDetails/>
            },
            {
              path:"self-identification",
              element:<SelfIdentification/>
            }
          ]
      },
      {
        path:"edit-employee-details/:employeeId",
        element:<EditDetails/>
      },
      // Salary Component
      {
        path:"statutory-component",
        element:<StatutoryComponent/>,
      },
      {
        path:"statutory-component/form",
        element:<StatutoryComponentForm/>,
      },
      {
        path:"new-salary-component",
        element:<NewSalaryComponent/>,
        children:[
          {
             index:true,
             element:<Type/>,
          },
          {
            path:"form",
            element:<Form/>,
          },
        ]
      },
      {
        path:"salary-components-listing",
        element:<SalaryComponentListing/>,
        children:[
          {
             index:true,
             element:<Earnings/>,
          },
          {
            path:"reimbursements",
            element:<Reimbursements/>,
          },
          {
            path:"deductions",
            element:<Deductions/>,
          },
        ]
      },
      //Leave
      {
        path: "leave-dashboard",
        element: <LeaveDashboard />,
      },
      {
        path: "leave-requests",
        element: <LeaveRequests/>,
      },
      {
        path: "leave-requests-details/:id",
        element: <RequestDetails/>,
      },
      {
        path: "leave-policy",
        element: <LeavePolicy />,
        children:[{
          index:true,
          element:<LeavePolicyListing/>
        },
        {
          path:"settings",
          element:<LeavePolicyDetails/>
        },
        {
          path:"assign-policy",
          element:<LeavePolicyAssignPolicy/>
        },
        {
          path:"rules",
          element:<LeavePolicyRules/>
        },
        {
          path:"edit",
          element:<LeavePolicyEdit/>
        },
      ]
      },
      {
        path: "leave-type",
        element: <LeaveType />,
        children:[{
          index:true,
          element:<LeaveTypeListing/>
        },
        {
          path:"form",
          element:<LeaveTypeForm/>
        }
      ]
      },
      {
        path: "machines",
        element: <Machines />,
        children:[{
          index:true,
          element:<MachineListing/>
        },
        {
          path:"form",
          element:<MachineForm/>
        }
      ]
      },
      {
        path: "software",
        element: <Software/>,
        children:[{
          index:true,
          element:<SoftwareListing/>
        },
        {
          path:"form",
          element:<SoftwareForm/>
        }
      ]
      },
      //settings
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "settings/geofence",
        element: <Geofence/>
      },
      {
        path: "settings/payroll/dashboard",
        element: <PayrollDashboard/>,
      },
      {
        path: "settings/payroll/paygroup",
        element: <PayGroup/>,
      },
      {
        path: "settings/payroll/paygroupdetails",
        element: <PayGroupDetail/>,
      },
      {
        path: "settings/payroll/paygroupdetails/approvals",
        element: <Approvals/>,
      },
      {
        path: "settings/payroll/salarytemplate",
        element: <SalaryTemplate/>,
      },
      {
        path: "settings/payroll/allemployeepayroll",
        element: <AllEmployeePayroll/>,
      },
      //timesheet
      {
        path: "timesheet",
        element: <Timesheet/>,
      },
      {
        path: "timesheet-settings",
        element: <TimeSheetGeneralSettings/>,
        children:[
          {
            index:true,
            element:<Timelog/>
          },
          {
            path:"employee-rate",
            element:<EmployeeRate/>
          },
          {
            path:"project-configuration",
            element:<ProjectConfiguration/>
          },
          {
            path:"client",
            element:<Client/>
          }
        ]
      },
      // employee side

      {
        path: "leave-request",
        element: <LeaveRequest />,
      },
      {
        path: "employee-expense",
        element: <Expense />,
      },
      {
        path: "employee-expense/add-new",
        element: <AddNewExpense/>,
      },
      {
        path: "employee-expense/my-expense",
        element: <MyExpenses/>,
      },
      {
        path: "employee-attendance-dashboard",
        element: <EmployeeAttendanceDashboard/>
      }
    ],
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/recover-account",
    element: <RecoverAccount />,
  },
]);

function App() {
  return <RouterProvider router={appRouter} />;
}

export default App;
