import { getCall, putCall } from "./api";

//common for trip and expense
export const getPolicyCategoryById = async (id) => {
  const request = {
    path: `/policy-cateogary/id/${id}`,
  };
  return await getCall(request);
};

export const updatePolicyCategory = async (id,input = {}) => {
  const request = {
    path: `/policy-cateogary/${id}`,
    bodyParams: input
  };
  return await putCall(request);
};

//type="TRIP"

export const getAllVehicleType = async (legalUnit) => {
    const request = {
      path: `/policy-cateogary/${legalUnit}?type=TRIP`,
    };
    return await getCall(request);
};

//type="ANY"

export const getAllExpenseCategory = async (legalUnit) => {
  console.log(legalUnit);
  const request = {
    path: `/policy-cateogary/${legalUnit}?type=ANY`,
  };
  return await getCall(request);
};