import {getCall} from "./api";

export const getDepartmentByLegalUnit = async (legalUnit) => {
  const request = {
    path: `/department/legal-unit/${legalUnit}`,
  };
  return await getCall(request);
};

export const getDepartments = async() =>{
  const request = {
    path: `/department/organization`,
  };
  return await getCall(request);
}