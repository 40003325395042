import React, {useState} from 'react'
import { Box } from '@mui/material'
import MonthPicker from '../../../../../component/MonthPicker'
import { PolicyTableCell } from '../../../../../styles/PolicyTableCell'
import { PolicyTableHeadings } from '../../../../../styles/PolicyTableHeadings'
import PaginationTable from '../../../../../component/PaginationTable'

const TableView = () => {
  
  const [data, setData] = useState([{}]);

  const headings = [
    { label: "Date", style: {...PolicyTableHeadings, width:"6.93rem", borderTopLeftRadius: "12px"}},
    { label: "Effective Hours", style: {...PolicyTableHeadings,  width:"12.25rem"}},
    { label: "Gross Hours", style: {...PolicyTableHeadings, width:"6.62rem"}},
    { label: "Break", style: { ...PolicyTableHeadings,width:"5.93rem", borderTopRightRadius: "12px"}}
  ];

  return (
    <Box sx={{display:"flex", flexDirection:"column", gap:"1rem"}}>
          <MonthPicker/>
          <PaginationTable
            rows={data}
            headings={headings}
            noRecordMessage={"No Record Found"}
            renderCell={(row) => (
                <>
                <PolicyTableCell>{row?.id}</PolicyTableCell>
                <PolicyTableCell>{row.type}</PolicyTableCell>
                <PolicyTableCell>{row.date}</PolicyTableCell>
                <PolicyTableCell>
                <Box sx={{width:"100%", display:"flex", justifyContent:"center"}}>
                        <Box sx={{
                                    width:"5.81rem",
                                    borderRadius:"4px", 
                                    padding:"4px 8px",
                                    textAlign:"center",
                                    backgroundColor: row.status === "pending" ? "#FFA80033" : (row.status === "rejected" ? "#E493931A" : "#03B85833"),
                                    color: row.status === "pending" ? "#FFA800" : (row.status === "rejected" ? "#E52E2E" : "#227C63")
                                    }}>
                                {row.status}
                            </Box>
                </Box>
                </PolicyTableCell>
                </>
            )}
          />
    </Box>
  )
}

export default TableView