import React, {useState, useEffect} from "react";
import {Box, Button, CircularProgress} from "@mui/material";
import MainBox from "../../../../container/MainBox";
import TopBar from "../../../../../../component/TopBar";
import CardItem from "../../../../../../component/CardItem";
import OfficeBuilding from "../../../../../../assets/icons/office-building.svg";
import Logout from "../../../../../../assets/icons/logout.svg";
import People from "../../../../../../assets/icons/people.svg";
import Card from "../../../../container/Card";
import FourCirclesIcon from "../../../../../../assets/icons/four-circles.svg";
import Ticket from "../../../../../../assets/icons/ticket.svg";
import Department from "../../../../../../component/Department";
import DashboardSectionHeading from "../../../../../../component/DashboardSectionHeading";
import TV from "../../../../../../assets/icons/tv.svg";
import { Link } from "react-router-dom";

const BtnStyle = {
    borderRadius: '4px',
    fontSize: '0.875rem',
    lineHeight: '1.31rem',
    fontWeight:"300",
    color: '#1E1E1E',
    height: "2rem", 
    width:"10.25rem",
    padding:"0.75rem",
    backgroundColor:"#0081FF",
    color:"#FFFFFF",
    textTransform:"none",
    '&:hover': {
        border: '1px solid #0081FF', 
        color: '#0081FF' 
    }
}

const PayrollDashboard = () => {

  const [loading,setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    setLoading(false);
  },[]);

  return (
    loading?(
      <Box sx={{width:"100%",display:"flex", justifyContent:"center"}}>
        <CircularProgress/>
      </Box>
    ):(
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Payroll"} 
          breadcrumbs={[{label:"Settings", href:""},{label:"Payroll", href:""},{label:"Dashboard", href:""}]}
        />
        <MainBox sx={{ padding: "2rem 3rem"}}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem", alignItems:"end" }}>
                <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                    Dashboard
                </Box>
                <Link to={"/app/settings/payroll/paygroup"} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Button sx={BtnStyle}>
                        Run Payroll
                    </Button>
                </Link>
            </Box>
            <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", gap: "1.75rem"}}>
                  <CardItem icon={People} Heading={"Total payable Employees"} subHeading={"Calender days - 30 days"}>
                    <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                        --
                    </Box>
                    <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                        Payment processed out of: 
                    </Box>
                  </CardItem>
                  <CardItem icon={OfficeBuilding} Heading={"Total Net Payroll"} subHeading={"Total Deduction"}>
                    <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                        --
                    </Box>
                    <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                        Total employees : 72
                    </Box>
                  </CardItem>
                  <CardItem icon={Logout} Heading={"Payroll Period"} subHeading={"Total Deduction"}>
                    <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                        --
                    </Box>
                    <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                        Upcoming Payment Date
                    </Box>
                  </CardItem>
            </Box>
            <Box sx={{display:"flex", alignItems:"stretch", gap:"1.187rem", marginBlock:"1.125rem"}}>
                    <Box sx={{width:"48%", flex:"1", display:"flex", flexDirection:"column", gap:"1rem", justifyContent:"space-between"}}>
                      <Card sx={{flex:"1", padding:"1.5rem", height:"16.5rem"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"end" }}>
                              <DashboardSectionHeading icon={TV} label={"Overview"}/>
                              <Department/>
                        </Box>
                      </Card>
                      <Card sx={{flex:"1",height:"16.5rem", padding:"1.5rem"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"end" }}>
                                <DashboardSectionHeading icon={Ticket} label={"Payroll Activity"}/>
                                <Button 
                                 sx={{fontSize:"0.875rem", lineHeight:"1.5rem", color:"#4F4F50", textTransform:"none", textDecoration:"underline"}}
                                >
                                  <Link 
                                   style={{ textDecoration: 'none', color: 'inherit' }}
                                   to=""
                                  >
                                     more info
                                  </Link>
                                </Button>
                          </Box>
                         
                      </Card>
                    </Box>
                    <Card sx={{width:"48%", height:"37rem", padding:"1.5rem", display:"flex", flexDirection:"column", gap:"1.175rem"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"end" }}>
                            <DashboardSectionHeading icon={FourCirclesIcon} label={"Payroll Cost Distribution"}/>
                            <Button  
                              sx={{fontSize:"0.875rem", lineHeight:"1.5rem", color:"#4F4F50", textTransform:"none", textDecoration:"underline"}}
                            >
                              <Link 
                               style={{ textDecoration: 'none', color: 'inherit' }}
                               to=""
                              >
                                  View Details
                              </Link>
                            </Button>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between"}}>
                              <Department/>
                        </Box>
                       
                    </Card>
              </Box>
        </MainBox>
     </Box>
    )
  );
};

export default PayrollDashboard;
