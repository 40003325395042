import { postCall, getCall,  putCall  } from "./api";

// trips policy

export const createTripPolicy = async (input = {}) => {
  const request = {
    path: `/policy`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const getAllTripsPolicy = async (legalUnit) => {
    console.log(legalUnit);
    const request = {
      path: `/policy/${legalUnit}?type=TRIP`,
    };
    return await getCall(request);
};

export const updateTripPolicy = async (policyId, input = {}) => {
  const request = {
    path: `/policy/${policyId}`,
    bodyParams: input,
  };
  return await putCall(request);
};

// add approver to policy

export const addApprovers = async (policyId, input = {}) => {
  const request = {
    path: `/policy/${policyId}`,
    bodyParams: input,
  };
  return await putCall(request);
};


  // vehicle type

  export const createVehicleType = async (input = {}) => {
    const request = {
      path: `/policy-cateogary`,
      bodyParams: input,
    };
    return await postCall(request);
  };
  
  export const getAllVehicleType = async (legalUnit) => {
    const request = {
      path: `/policy-cateogary/${legalUnit}?type=TRIP`,
    };
    return await getCall(request);
  };

  export const getVehicleTypeById = async (id) => {
    const request = {
      path: `/policy-cateogary/id/${id}`,
    };
    return await getCall(request);
  };

  export const updateVehicleType = async (id,input = {}) => {
    const request = {
      path: `/policy-cateogary/${id}`,
      bodyParams: input
    };
    return await putCall(request);
  };

  // Get all trips of organisation

  export const getAllTrips = async () => {
    const request = {
      path: `/trip/organization`,
    };
    return await getCall(request);
  };

  // Get trip by trip id

  export const getTripById = async (tripId) => {
    const request = {
      path: `/trip/${tripId}`,
    };
    return await getCall(request);
  };

  //Get all trips that have converted to expenses

  export const getTrip = async (pageNumber, pageSize, date, department, employeeName) => {
    let queryParams = `?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  
    if (date) {
      queryParams += `&date=${date}`;
    }
    if (department) {
      queryParams += `&department=${department}`;
    }
    if (employeeName) {
      queryParams += `&employeeName=${employeeName}`;
    }
  
    const request = {
      path: `/expense/trip${queryParams}`,
    };
    
    return await getCall(request);
  };

  //get latest trip by employee id

  export const getLatestTripByEmployeeId = async (empId) => {
    const request = {
      path: `/trip/latest/${empId}`,
    };
    return await getCall(request);
  }